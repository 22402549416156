import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Gallery, ImageItem} from '@ngx-gallery/core';
import {Images} from 'src/modules/catalogue/models/image.model';
import {Lightbox} from '@ngx-gallery/lightbox';

@Component({
    selector: 'app-image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {

    @Input() public images: Images;
    @Input() public name: string;

    @ViewChild('itemTemplate') public template: TemplateRef<any>;
    public readonly galleryId = 'gallery';

    constructor(public gallery: Gallery, public lightbox: Lightbox) {
    }

    public ngOnInit() {

        const galleryRef = this.gallery.ref(this.galleryId);
        galleryRef.setConfig({
            loadingMode: 'indeterminate',
            zoomOut: 7,
            itemTemplate: this.template,
            counter: false
        });
        galleryRef.load(this.images.map(image =>
            new ImageItem({
                src: image.src,
                thumb: image.thumb,
                title: image.caption
            })
        ));
    }
}

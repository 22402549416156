import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import {LOGGED_IN, LOGGED_OUT, LOGGED_OUT_NO_REDIRECT, UNAUTHORIZED} from './auth.actions';
import {User} from '../../models/user.model';
import {TranslateService} from '@ngx-translate/core';
import {SharedApiService} from "../../services/api.service";

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private translateService: TranslateService,
        private apiService: SharedApiService
    ) {
    }

    @Effect({ dispatch: false })
    loginReroute$ = this.actions$.pipe(
        ofType(LOGGED_IN),
        map((action: any) => action.payload),
        tap((user: User) => {
            this.translateService.use(user.lang);
        }),
        tap((user: User) => {
            this.apiService.getCurrentSigPartner().subscribe(data => {
                if (data['organisation']) {
                    user.organisation = data['organisation']['name'];
                    user.organisationCid = data['organisation']['debitCode'];
                    user.organisationGuid = data['organisation']['guid'];

                    if (data['organisation']['sigPartnerOrganisationSubSegment'] && data['organisation']['sigPartnerOrganisationSubSegment']['segment']) {
                        user.segment = data['organisation']['sigPartnerOrganisationSubSegment']['segment']['cid'];
                    }
                }
            });
        }),
    );

    @Effect({ dispatch: false })
    logoutReroute$ = this.actions$.pipe(
        ofType(LOGGED_OUT),
        map((action: any) => action.payload),
        tap(() => { this.router.navigate(['/user/login']) })
    );

    @Effect({ dispatch: false })
    logoutNoReroute$ = this.actions$.pipe(
        ofType(LOGGED_OUT_NO_REDIRECT),
        map((action: any) => action.payload)
    );

    @Effect({ dispatch: false })
    unauthorizedReroute$ = this.actions$.pipe(
        ofType(UNAUTHORIZED),
        map((action: any) => action.payload),
        tap(() => {
            this.router.navigate(
                ['/user/login'],
                {
                    queryParams: {
                        'reason': 'expired'
                    }
                }
            )
        })
    );
}

import {Component} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ApiAwareHttpClient} from "../../services/api_aware_http_client";
import {Router} from "@angular/router";

@Component({
    templateUrl: './no-partner-organisation-modal.component.html',
})
export class NoPartnerOrganisationModalComponent {
    constructor(
        private modal: NgbActiveModal,
        private apiClient: ApiAwareHttpClient,
        private router: Router,
    ) {
    }

    public close() {
        this.apiClient.logout(false).subscribe(() => {
            this.modal.dismiss();
            this.router.navigateByUrl('/user/login');
        });
    }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe(
    {
        name: 'removeWhiteSpaces'
    }
)
export class RemoveWhiteSpacesPipe implements PipeTransform {
    public transform(value: string): string {
        // Remove spaces, ( and )
        return value.replace(/\s|\(|\)/g, '');
    }
}
import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {Cookies} from "../../models/cookies.model";
import {EnvService} from "../../../shared/services/env.service";

@Component({
    selector: 'app-cookie-bar',
    templateUrl: './cookie-bar.component.html'
})
export class CookieBarComponent {
    @ViewChild('cookieForm') public cookieForm: NgForm;
    @Output() public cookiesAccepted = new EventEmitter<string>();

    public showDetails: boolean = false;

    public cookies: Cookies = {
        acceptNecessaryCookies: true,
        acceptStatisticsCookies: false
    }

    constructor(
            public envService: EnvService,
    ) {
    }

    public toggleDetails(): void {
        this.showDetails = !this.showDetails;
    }

    public acceptAllCookies(): void {
        this.cookies.acceptNecessaryCookies = true;
        this.cookies.acceptStatisticsCookies = true;

        this.acceptCookies();
    }

    public acceptCookies(): void {
        this.cookiesAccepted.emit(JSON.stringify(this.cookies));
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Article} from "../../../catalogue/models/article.model";
import {FormatterService} from "../../services/formatter.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SharedApiService} from "../../services/api.service";
import {ArticleStock} from '../../models/article-stock.model';
import {AuthService} from "../../services/auth.service";
import {ArticlePriceService} from "../../services/article-price.service";
import {ArticleStockByLocationService} from '../../services/article-stock-by-location.service';
import {ArticleStockByLocation} from '../../models/article-stock-by-location.model';
import {slugify} from "../../util/slugify";

@Component({
    selector: 'app-shared-article-details',
    templateUrl: './article-details.component.html',
})
export class ArticleDetailsComponent implements OnInit {
    @Input() public article: Article;
    @Input() public showStock: boolean = true;
    @Input() public locationGuid: string = null;
    @Input() public showStockByLocation: boolean = false;
    @Output() public articleClicked: EventEmitter<void> = new EventEmitter<void>();

    public stock: ArticleStock;
    public stockByLocation: ArticleStockByLocation;
    public hasVolumePrices: boolean = false;
    public volumePrices: Array<any> = [];
    public discountPercentage: number;

    constructor(
            public authService: AuthService,
            private formatterService: FormatterService,
            private apiService: SharedApiService,
            private modalService: NgbModal,
            private articlePriceService: ArticlePriceService,
            private articleStockByLocationService: ArticleStockByLocationService
    ) {
    }

    public ngOnInit() {
        if (this.authService.hasRoleOrderer() && this.showStock) {
            if (this.showStockByLocation) {
                this.articleStockByLocationService
                        .subscribeToArticle(this.article.guid)
                        .subscribe(stock => this.stockByLocation = stock);
            }

            this.articlePriceService
                    .subscribeToArticle(this.article.guid)
                    .subscribe(price => {
                        this.hasVolumePrices = price.hasVolumePrices;
                        this.volumePrices = price.volumePrices;
                        this.discountPercentage = price.discountPercentage;

                        if (price.hasOwnProperty('stock')) {
                            this.stock = {
                                articleGuid: price.articleGuid,
                                stock: price.stock
                            } as ArticleStock;
                        }
                    });
        }
    }

    public stockClass(): string {
        if (this.article.isCommission) {
            return 'on_request';
        }

        if (this.showStockByLocation && this.locationGuid) {
            if (this.stockByLocation && 'stock' in this.stockByLocation && this.locationGuid in this.stockByLocation.stock) {
                const stock = this.stockByLocation.stock[this.locationGuid].stock;
                if (stock <= 0) {
                    return 'out';
                }

                return 'in';
            }

            return 'unknown';
        }

        if (!this.stock || this.stock.stock === null || this.stock.stock === undefined) {
            return 'unknown';
        }

        if (this.stock.stock <= 0) {
            return 'out';
        }

        return 'in';
    }

    public loadStockForMultipleLocations() {
        if (!this.stockByLocation) {
            this.articleStockByLocationService
                    .subscribeToArticle(this.article.guid)
                    .subscribe(stock => this.stockByLocation = stock);
        }
    }

    public slug(name: string) {
        return slugify(name);
    }
}

import {Component, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {LoaderService} from "../../services/loader.service";
import {Loader} from "../../models/loader.model";

@Component(
    {
        selector: 'app-loader',
        templateUrl: './loader.component.html'
    }
)
export class LoaderComponent implements OnDestroy {
    show = false;

    private subscription: Subscription;

    constructor(private loaderService: LoaderService) {
        this.subscription = this.loaderService.loader.subscribe((state: Loader) => {
            this.show = state.show;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

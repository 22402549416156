import {Component, Input, OnInit} from "@angular/core";
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {NewsItem, NewsItems} from "../../../news/models/news-item.model ";

@Component({
    selector: 'app-news-carousel',
    templateUrl: './news-carousel.component.html'
})
export class NewsCarouselComponent implements OnInit {

    @Input() public newsItems: NewsItems;
    @Input() public title: string = null;
    @Input() public homepage: boolean = false;

    public carouselConfig: SwiperConfigInterface = {
        speed: 1000,
        direction: 'horizontal',
        freeMode: true,
        slidesPerView: 3,
        spaceBetween: 32,
        initialSlide: 0,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            300: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            }
        }
    };

    public ngOnInit() {
        if (this.newsItems && this.newsItems.length < 4) {
            this.carouselConfig.autoplay = false;
        }
    }

    public showNewsItem(newsItem: NewsItem): boolean {
        return !(this.homepage && !newsItem.show_on_home_page);
    }
}

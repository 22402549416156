import {Injectable} from '@angular/core';
import {ApiAdapter} from "../../shared/adapter/api.adapter";
import {ShoppingCartCount} from "../../shared/models/shopping-cart-count";

@Injectable()
export class ShoppingCartCountAdapter implements ApiAdapter<ShoppingCartCount> {

    constructor() {
    }

    public fromApi(shoppingCartCount: any) {
        return {
            total: Number(shoppingCartCount.total),
        };
    }
}

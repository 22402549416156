import {Component, Input, OnInit} from '@angular/core';
import {SharedApiService} from '../../services/api.service';

@Component({
  selector: 'app-shared-article-info',
  templateUrl: './article-info.component.html',
})
export class ArticleInfoComponent implements OnInit {

  @Input() public articleGuid: string;
  public article: any;

  constructor(
      public apiService: SharedApiService
  ) {
  }

  public ngOnInit() {
    if (this.articleGuid && !this.article) {
      this.apiService.getArticle(this.articleGuid, {spinner: false}).subscribe(data => {
        this.article = data;
      });
    }
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CatalogueLink, CatalogueLinks} from 'src/modules/app/models/cataloguelink.model';
import {EnvService} from "../../../../shared/services/env.service";

@Component({
    selector: 'app-header-catalog',
    templateUrl: './catalog.component.html',
    styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
    @Input() public onProductGroupClick: (productGroup: CatalogueLink) => void;
    @Input() public hierarchy: CatalogueLink;
    @Output() public productGroupSelected = new EventEmitter<CatalogueLink>();

    public specialties: CatalogueLinks = [];
    public activeSpecialty: CatalogueLink = null;
    private timer: number = null;

    constructor(
            public envService: EnvService
    ) {
    }

    public ngOnInit() {
        this.specialties = this.hierarchy.hierarchies;
        this.activeSpecialty = this.hierarchy.hierarchies[0];
    }

    public switchSubSpecialties(specialty: CatalogueLink) {
        if (this.timer) {
            this.clearTimer();
        }

        this.timer = setTimeout(() => {
            this.activeSpecialty = specialty;
        }, 200);
    }

    public subSpecialtyClick(subSpecialty: CatalogueLink) {
        this.productGroupSelected.emit(subSpecialty);

        this.activeSpecialty = this.specialties[0];
    }

    public productGroupClick(productGroup: CatalogueLink) {
        this.productGroupSelected.emit(productGroup);

        this.activeSpecialty = this.specialties[0];
    }

    public clearTimer() {
        clearTimeout(this.timer);
    }

    public getDryFinishSpecialties(): CatalogueLinks {
        return this.hierarchy.hierarchies.filter(hierarchy => {
            return ['36', '39', '41', '524'].includes(hierarchy.cid);
        })
    }

    public getWetFinishSpecialties(): CatalogueLinks {
        return this.hierarchy.hierarchies.filter(hierarchy => {
            return ['35', '37', '38', '40'].includes(hierarchy.cid);
        })
    }

    public getGeneralFinishItems(): CatalogueLinks {
        return this.hierarchy.hierarchies.filter(hierarchy => {
            return ['42', '514'].includes(hierarchy.cid);
        });
    }
}

import {Component, Input} from '@angular/core';
import {ArticlePrice} from "../../models/article-price.model";
import {FormatterService} from "../../services/formatter.service";
import {ArticlePriceService} from "../../services/article-price.service";

@Component(
        {
            selector: 'app-shared-article-price-total',
            templateUrl: './article-price-total.component.html'
        }
)
export class ArticlePriceTotalComponent {
    @Input() private values: {
        articlePrice?: ArticlePrice,
        quantity: number,
    };

    constructor(
            private articlePriceService: ArticlePriceService,
            public formatterService: FormatterService,
    ) {

    }

    get articlePrice() {
        return this.values.articlePrice;
    }

    get nettPriceInOrderingUnitOfMeasure() {
        return this.articlePriceService.getNettPriceInOrderingUnitOfMeasure(this.values.articlePrice, this.values.quantity);
    }

    get quantity() {
        return this.values.quantity;
    }

    public splitPrice(price) {
        return parseFloat(price).toFixed(2).split('.');
    }
}

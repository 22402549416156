import { Component, Input } from '@angular/core';
import { Article } from '../../../catalogue/models/article.model';

@Component({
    selector: 'app-article-details-view-wrapper',
    templateUrl: './article-details-view-wrapper.component.html',
    styleUrls: ['./article-details-view-wrapper.component.scss']
})
export class ArticleDetailsViewWrapperComponent {
    @Input() public values: {
        article: Article,
        showStock: boolean
    };

    get showStock(): boolean {
        return 'showStock' in this.values && this.values.showStock === true;
    }
}

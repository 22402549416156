import { ErrorState, Error } from './error.model';
import { ErrorActions, ERROR_THROWN } from './error.actions';

const initialState: ErrorState = {
    errors: []
};

export function ErrorReducer(
    state: ErrorState = initialState,
    action: ErrorActions
): ErrorState {
    switch (action.type) {
        case ERROR_THROWN:
            state.errors.push(action.payload);
            return {...state, errors: state.errors}
        default:
            return state;
    }
}
import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SafeResourceUrl} from "@angular/platform-browser";

@Component(
        {
            templateUrl: './video-modal.component.html'
        }
)
export class VideoModalComponent {
    @Input() public videoUrl: SafeResourceUrl;

    constructor(public modal: NgbActiveModal) {
    }

    public close() {
        this.modal.dismiss();
    }
}

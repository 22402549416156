import {Injectable} from '@angular/core';
import {ApiAdapter} from "./api.adapter";
import {SubSegment} from "../models/sub-segment.model";
import {SegmentAdapter} from "./segment.adapter";

@Injectable()
export class SubSegmentAdapter implements ApiAdapter<SubSegment> {
    constructor(
        private segmentAdapter: SegmentAdapter
    ) {
    }

    public fromApi(subSegment: any) {
        return {
            guid: subSegment.guid,
            cid: subSegment.cid,
            name: subSegment.name,
            segment: this.segmentAdapter.fromApi(subSegment.segment),
        };
    }
}
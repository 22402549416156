import {Component, OnInit, ViewChild} from "@angular/core";
import {FormControl, NgForm, Validators} from "@angular/forms";
import {SharedApiService} from "../../services/api.service";
import {FormFieldService} from "../../services/form-field.service";
import {Record} from "../../models/record.model";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {FormGuidEnum} from "../../enums/form-guid.enum";
import {Observable} from "rxjs";
import {RecordResponse} from "../../models/record-response.model";
import {ViewportScroller} from '@angular/common';
import {Store} from "@ngrx/store";
import {AuthenticationState} from "../../store/auth/auth.model";
import {User} from "../../models/user.model";

@Component({
    templateUrl: './form-question.component.html'
})
export class FormQuestionComponent implements OnInit {
    @ViewChild('questionForm') public questionForm: NgForm;

    public record: Record;
    public lng: string = null;
    public formFields: {};
    public siteKey: string = null;
    public pageSubmitted = false;
    public requiredEmailFormControl: FormControl;
    public validEmail: boolean = true;
    public user?: User;

    constructor(
        private route: ActivatedRoute,
        private apiService: SharedApiService,
        private formFieldsService: FormFieldService,
        private translateService: TranslateService,
        private viewportScroller: ViewportScroller,
        public store: Store<{ auth: AuthenticationState }>,
    ) {
        this.apiService.getCaptchaConfiguration().subscribe(data => {
            this.siteKey = data['captcha-sitekey']
        });

        this.requiredEmailFormControl = new FormControl(
            '',
            [
                Validators.required,
                Validators.email
            ]
        );
    }

    public ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.lng = this.translateService.currentLang;
            this.record = data.record;
            this.formFields = this.formFieldsService.getFormFieldsGrouped(this.record.formFields.c, this.record.model);
        });

        this.store.select('auth').subscribe((state: AuthenticationState) => {
            this.user = state.user;
        });
    }

    public validateEmail() {
        this.requiredEmailFormControl.setValue(this.record.model['email']);
        this.validEmail = this.requiredEmailFormControl.valid;
    }

    public submit() {
        if (this.questionForm.invalid || !this.validEmail) {
            this.formFieldsService.markFormFieldsTouched(this.questionForm);

            return;
        }

        if (this.user) {
            this.record.model['companyName'] = this.user.organisation;
            this.record.model['name'] = this.user.name;
            this.record.model['email'] = this.user.email;
        }

        return this.saveQuestion().subscribe(() => {
            this.pageSubmitted = true;
        })
    }

    private saveQuestion(): Observable<RecordResponse> {
        return this.apiService.saveRecord(FormGuidEnum.QUESTION, this.record.model);
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }
}

import {Component, Input, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SharedApiService} from "../../../../../shared/services/api.service";
import {ActivatedRoute} from "@angular/router";
import {FormFieldService} from "../../../../../shared/services/form-field.service";
import {TranslateService} from "@ngx-translate/core";
import {NgForm} from "@angular/forms";
import {Record} from "../../../../../shared/models/record.model";
import {Observable} from "rxjs";
import {RecordResponse} from "../../../../../shared/models/record-response.model";
import {FormGuidEnum} from "../../../../../shared/enums/form-guid.enum";
import {User} from "../../../../../shared/models/user.model";
import {Store} from "@ngrx/store";
import {AuthenticationState} from "../../../../../shared/store/auth/auth.model";

@Component({
    templateUrl: './question-modal.component.html',
    styleUrls: ['./question-modal.component.scss']
})
export class QuestionModalComponent {
    @ViewChild('questionForm') public questionForm: NgForm;

    @Input() public isPartnerPage: boolean = false;

    public user?: User;
    public record: Record;
    public lng: string = null;
    public formFields: {};
    public siteKey: string = null;
    public formSubmitted = false;

    constructor(
            private route: ActivatedRoute,
            public modal: NgbActiveModal,
            private apiService: SharedApiService,
            private formFieldsService: FormFieldService,
            private translateService: TranslateService,
            public store: Store<{ auth: AuthenticationState }>,
    ) {
        this.apiService.getCaptchaConfiguration().subscribe(data => {
            this.siteKey = data['captcha-sitekey']
        });
    }

    public ngOnInit(): void {
        this.lng = this.translateService.currentLang;
        this.apiService.getRecord(FormGuidEnum.QUESTION, null).subscribe(data => {
            this.record = data;
            this.formFields = this.formFieldsService.getFormFieldsGrouped(this.record.formFields.c, this.record.model);
        });

        this.store.select('auth').subscribe((state: AuthenticationState) => {
            this.user = state.user;
        });
    }

    public submit() {
        if (this.questionForm.invalid) {
            this.formFieldsService.markFormFieldsTouched(this.questionForm);

            return;
        }

        if (this.user) {
            this.record.model['companyName'] = this.user.organisation;
            this.record.model['name'] = this.user.name;
            this.record.model['email'] = this.user.email;
        }

        return this.saveQuestion().subscribe(() => {
            this.formSubmitted = true;
        })
    }

    private saveQuestion(): Observable<RecordResponse> {
        return this.apiService.saveRecord(FormGuidEnum.QUESTION, this.record.model);
    }
}

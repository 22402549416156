import {Injectable} from '@angular/core';
import {
    AppSearch,
    AppSearchFacets,
    AppSearchFacet,
    AppSearchFacetOption,
    AppSearchMeta,
    AppSearchMetaPage,
    AppSearchResult
} from "../models/appSearch.model";
import {ApiAdapter} from "../../shared/adapter/api.adapter";
import {castToArray} from "../../shared/util/array";

@Injectable()
export class AppSearchAdapter implements ApiAdapter<AppSearch> {
    public fromApi(appSearch: any) {
        return {
            query: appSearch['query'],
            meta: {
                page: {
                    current: appSearch['meta']['page']['current'],
                    totalPages: appSearch['meta']['page']['total_pages'],
                    totalResults: appSearch['meta']['page']['total_results'],
                    size: appSearch['meta']['page']['size'],
                } as AppSearchMetaPage,
            } as AppSearchMeta,
            results: (castToArray(appSearch['results']) || [])
                    .map((appSearchResult: any) => {
                        return {
                            id: this.getValue(appSearchResult['id']),
                            guid: this.getValue(appSearchResult['guid']),
                            code: this.getValue(appSearchResult['code']),
                            name: this.getValue(appSearchResult['name']),
                            isCommission: this.getValue(appSearchResult['is_commission']) === 'true',
                            description: this.getValue(appSearchResult['description']),
                            mainImage: this.getValue(appSearchResult['main_image']),
                        } as AppSearchResult;
                    }),
            facets: (castToArray(appSearch['facets']) || [])
                    .map((appSearchFacets: Array<any>) => {
                        return appSearchFacets
                                .map((appSearchFacet: any) => {
                                    return {
                                        type: appSearchFacet['type'],
                                        cid: appSearchFacet['cid'],
                                        name: appSearchFacet['name'],
                                        options: (castToArray(appSearchFacet['data']) || [])
                                                .map((appSearchFacetOption: any) => {
                                                    return {
                                                        value: appSearchFacetOption['value'],
                                                        count: appSearchFacetOption['count'],
                                                        checked: appSearchFacetOption['checked'],
                                                        image: appSearchFacetOption['image'],
                                                    } as AppSearchFacetOption;
                                                })
                                    } as AppSearchFacet;
                                }) as AppSearchFacets
                    })
        };
    }

    public getValue(result?: Object) {
        if (result) {
            if ('snippet' in result && result['snippet']) {
                return result['snippet'];
            }
            if ('raw' in result && result['raw']) {
                return result['raw'];
            }
        }

        return null;
    }
}
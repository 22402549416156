import {Injectable} from '@angular/core';
import {AuthenticationState} from '../store/auth/auth.model';
import {Store} from '@ngrx/store';
import {User} from '../models/user.model';

@Injectable()
export class AuthService {
    /**
     * User access token data
     */
    private user: User;

    constructor(
            private store: Store<{auth: AuthenticationState}>
    ) {
        this.store.select('auth').subscribe((state: AuthenticationState) => {
            this.user = state.user;
        });
    }

    public hasRole(role: string): boolean {
        if (!this.isAuthenticated() || !Array.isArray(this.user.roles)) {
            return false;
        }

        return this.user.roles.indexOf(role) > -1;
    }

    public isAuthenticated(): boolean {
        return !!this.user;
    }

    public getUser(): User | null {
        return this.user || null;
    }

    public hasRoleOrderer(): boolean {
        return this.hasRole('ROLE_SIG_ORDERER');
    }

    public hasFinishSegment(): boolean {
        return this.user && this.user.segment && ['dry_finish', 'wet_finish'].includes(this.user.segment);
    }

    public hasDryFinishSegment(): boolean {
        return this.user && this.user.segment === 'dry_finish';
    }

    public hasWetFinishSegment(): boolean {
        return this.user && this.user.segment === 'wet_finish';
    }

    public hasTechnicalInsulationSegment(): boolean {
        return this.user && this.user.segment === 'technical_insulation';
    }
}

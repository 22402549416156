import {Component, Input} from '@angular/core';
import {TableBodyRowCell} from "../../../view/models/table-body-row-cell.model";

@Component({
    templateUrl: './view-order-code.component.html',
    styleUrls: ['./view-order-code.component.scss'],
})
export class ViewOrderCodeComponent {
    @Input() public values: {
        orderCodeCell: TableBodyRowCell,
        orderStatusCell: TableBodyRowCell,
    };
}

import {Injectable} from '@angular/core';
import {ApiAdapter} from "../../shared/adapter/api.adapter";
import {Order} from "../models/order.model";
import {ProjectAdapter} from "../../shared/adapter/project.adapter";
import {SigPartnerAdapter} from "../../user/adapter/sig-partner.adapter";
import {AddressAdapter} from "../../shared/adapter/address.adapter";
import {OrderAddressAdapter} from "./orderAddress.adapter";
import {OrderStatusAdapter} from "./orderStatus.adapter";
import {OrderServiceLineAdapter} from './orderServiceLine.adapter';
import {castToArray} from "../../shared/util/array";

@Injectable()
export class OrderAdapter implements ApiAdapter<Order> {

    constructor(
            private projectAdapter: ProjectAdapter,
            private sigPartnerAdapter: SigPartnerAdapter,
            private addressAdapter: AddressAdapter,
            private orderAddressAdapter: OrderAddressAdapter,
            private orderStatusAdapter: OrderStatusAdapter,
            private orderServiceLineAdapter: OrderServiceLineAdapter,
    ) {
    }

    public fromApi(order: any) {
        const result = {
            guid: order.guid,
            cid: order.cid,
            project: null,
            reference: order.reference,
            preferred_delivery_date: order.preferredDeliveryDate,
            preferred_delivery_time: null,
            contact_person: null,
            contact_person_name: order.contactPersonName,
            contact_person_phone: order.contactPersonPhone,
            remarks: order.remarks,
            delivery_method: order.deliveryMethod,
            partner_address: null,
            order_address: null,
            warehouse: null,
            user: null,
            partner: null,
            order_status: null,
            date: order.date,
            service_lines: [],
        };

        if (order.project) {
            result.project = this.projectAdapter.fromApi(order.project);
        }

        if (order.preferredDeliveryTime) {
            result.preferred_delivery_time = {
                guid: order.preferredDeliveryTime.guid,
                costs: order.preferredDeliveryTime.costs,
                cid: order.preferredDeliveryTime.cid
            };
        }

        if (order.contactPerson) {
            result.contact_person = this.sigPartnerAdapter.fromApi(order.contactPerson);
        }

        if (order.partnerAddress) {
            result.partner_address = this.addressAdapter.fromApi(order.partnerAddress);
        }

        if (order.address) {
            result.order_address = this.orderAddressAdapter.fromApi(order.address);
        }

        if (order.warehouse) {
            result.warehouse = {
                guid: order.warehouse.guid,
                name: order.warehouse.name,
            };

            if (order.warehouse.defaultAddress) {
                result.warehouse.address = {
                    street: order.warehouse.defaultAddress.street,
                    houseNumber: order.warehouse.defaultAddress.houseNumber,
                    houseNumberSuffix: order.warehouse.defaultAddress.houseNumberSuffix,
                    zipCode: order.warehouse.defaultAddress.zipcode,
                    city: order.warehouse.defaultAddress.city,
                };
            }
        }

        if (order.partner) {
            result.partner = this.sigPartnerAdapter.fromApi(order.partner);
        }

        if (order.status) {
            result.order_status = this.orderStatusAdapter.fromApi(order.status);
        }

        if (order.serviceLines) {
            result.service_lines = (castToArray(order.serviceLines as any[]) || [])
                    .map(line => this.orderServiceLineAdapter.fromApi(line));
        }

        return result;
    }
}

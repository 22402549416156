import {ApiAdapter} from '../../shared/adapter/api.adapter';
import {Injectable} from '@angular/core';
import {Product} from '../models/product.model ';
import {castToArray} from 'src/modules/shared/util/array';
import {slugify} from 'src/modules/shared/util/slugify';
import {RemoteFile} from "../models/remotefile.model";
import {ProductArticle} from "../models/productArticle.model";
import {ArticleAdapter} from "./article.adapter";
import {CatalogueLink} from "../../app/models/cataloguelink.model";

@Injectable()
export class ProductAdapter implements ApiAdapter<Product> {

    constructor(
            private articleAdapter: ArticleAdapter
    ) {
    }

    public fromApi(x: any) {
        return {
            id: Number(x.id),
            slug: slugify(x.name),
            guid: x.guid,
            name: x.name,
            description: x.description,
            faq: x.faq || [],
            productArticles: (castToArray(x.productArticles) || [])
                    .map((productArticle: ProductArticle) => {
                        return {
                            guid: productArticle.guid,
                            article: this.articleAdapter.fromApi(productArticle.article),
                        };
                    }),
            images: (castToArray(x.images) || [])
                    .map(image => {
                        return {
                            src: image.image,
                            thumb: image.image,
                            caption: image.name,
                        };
                    }),
            videos: (castToArray(x.videos) || [])
                    .map(video => {
                        return {
                            youtubeIdentifier: video.youtubeIdentifier,
                            name: video.name,
                        };
                    }),
            relatedProducts: (castToArray(x.relatedProducts) || [])
                    .map((z: any) => this.fromApi(z)),
            files: (castToArray(x.files) || [])
                    .map((file: any) => mapFileFromApi(file)),
            mainImage: x.mainImage,
            hierarchies: (castToArray(x.hierarchies) || [])
                    .map((catalogueLink: CatalogueLink) => {
                        return {
                            guid: catalogueLink.guid,
                            icon: catalogueLink.icon,
                            sorter: catalogueLink.sorter,
                            hierarchies: catalogueLink.hierarchies,
                            name: catalogueLink.name,
                        };
                    }),
        };
    }
}

function mapFileFromApi(file: any): RemoteFile {

    let icon = 'fa-file-alt';

    if (file.type && file.type.icon) {
        icon = file.type.icon;
    }

    return {
        name: file.name,
        url: file.url,
        icon: icon,
        file: {
            name: file.file.name,
        }
    };
}

import { Action } from '@ngrx/store';
import { User } from '../../models/user.model';

const KEY = 'AUTH';
export const LOGGED_IN = `[${KEY}] LoggedIn`;
export const REFRESHED_TOKENS = `[${KEY}] RefreshedTokens`;
export const LOGGED_OUT = `[${KEY}] LoggedOut`;
export const LOGGED_OUT_NO_REDIRECT = `[${KEY}] LoggedOutNoRedirect`;
export const UNAUTHORIZED = `[${KEY}] Unauthorized`;

export class LoggedInAction implements Action {
    public readonly type = LOGGED_IN;
    constructor(public payload: User) { }
}

export class LoggedOutAction implements Action {
    public readonly type = LOGGED_OUT;
    constructor(public payload = null) { }
}

export class LoggedOutNoRedirectAction implements Action {
    public readonly type = LOGGED_OUT_NO_REDIRECT;
    constructor(public payload = null) { }
}

export class UnauthorizedAction implements Action {
    public readonly type = UNAUTHORIZED
    constructor(public payload = null) { }
}

export type AuthenticationActions = LoggedInAction | LoggedOutAction | LoggedOutNoRedirectAction | UnauthorizedAction;


import {Injectable} from '@angular/core';
import {ApiAdapter} from "../../shared/adapter/api.adapter";
import {OrderStatus} from "../models/OrderStatus.model";

@Injectable()
export class OrderStatusAdapter implements ApiAdapter<OrderStatus> {

    constructor() {
    }

    public fromApi(orderStatus: any) {
        return {
            guid: orderStatus.guid,
            cid: orderStatus.cid,
            name: orderStatus.name
        };
    }
}

import {ApiAdapter} from "../../shared/adapter/api.adapter";
import {Injectable} from '@angular/core';
import {NewsCategory} from "../models/news-category.model ";
import {NewsItemAdapter} from "./newsItem.adapter";
import {NewsItem} from "../models/news-item.model ";
import {slugify} from "../../shared/util/slugify";
import {castToArray} from "../../shared/util/array";

@Injectable()
export class NewsCategoryAdapter implements ApiAdapter<NewsCategory> {
    constructor(
        private newsItemAdapter: NewsItemAdapter
    ) {
    }

    public fromApi(newsCategory: any) {
        return {
            guid: newsCategory.guid,
            name: newsCategory.name,
            slug: slugify(newsCategory.name),
            items: (castToArray(newsCategory.items) || [])
                .map((newsItem: NewsItem) => {
                    return this.newsItemAdapter.fromApi(newsItem);
                }),
        };
    }
}
import {ApiAdapter} from "../../shared/adapter/api.adapter";
import {Injectable} from '@angular/core';
import {NewsItem} from '../models/news-item.model ';
import {slugify} from "../../shared/util/slugify";

@Injectable()
export class NewsItemAdapter implements ApiAdapter<NewsItem> {
    public fromApi(newsItem: any) {
        return {
            guid: newsItem.guid,
            title: newsItem.title,
            slug: slugify(newsItem.title),
            introduction: newsItem.introduction,
            content: newsItem.content,
            date: newsItem.date,
            link: newsItem.link,
            image: newsItem.image,
            category: newsItem.category ? newsItem.category.name : null,
            categoryGuid: newsItem.category ? newsItem.category.guid : null,
            categorySlug: newsItem.category ? slugify(newsItem.category.name) : null,
            show_image_in_overview: newsItem.showImageInOverview,
            show_image_on_detail_page: newsItem.showImageOnDetailPage,
            show_on_home_page: newsItem.showOnHomePage,
            open_link_in_new_tab: newsItem.openLinkInNewTab,
            image_position_horizontal: newsItem.imagePositionHorizontal,
            image_position_vertical: newsItem.imagePositionVertical,
        };
    }
}
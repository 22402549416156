import {Component, Input} from '@angular/core';
import {ArticlePrice} from "../../models/article-price.model";
import {ArticlePriceService} from "../../services/article-price.service";

@Component(
        {
            selector: 'app-shared-article-price-per-price-unit',
            templateUrl: './article-price-per-price-unit.component.html'
        }
)
export class ArticlePricePerPriceUnitComponent {
    @Input() private values: {
        articlePrice?: ArticlePrice,
        hasTechnicalInsulationSegment: boolean,
        showDivider: boolean,
        quantity: number,
    }

    constructor(
            private articlePriceService: ArticlePriceService
    ) {

    }

    get articlePrice() {
        return this.values.articlePrice;
    }

    get nettPriceInPriceUnitOfMeasure() {
        return this.articlePriceService.getNettPriceInPriceUnitOfMeasure(this.values.articlePrice, this.values.quantity);
    }

    get grossPriceInPriceUnitOfMeasure() {
        return this.articlePriceService.getGrossPriceInPriceUnitOfMeasure(this.values.articlePrice, this.values.quantity);
    }

    get hasTechnicalInsulationSegment() {
        return this.values.hasTechnicalInsulationSegment;
    }

    get showDivider() {
        return this.values.showDivider;
    }

    public splitPrice(price) {
        return parseFloat(price).toFixed(2).split('.');
    }
}

import {Injectable} from '@angular/core';
import {ApiAdapter} from "./api.adapter";
import {WarehouseSubSegment} from "../models/warehouse-sub-segment.model";
import {SubSegmentAdapter} from "./sub-segment.adapter";

@Injectable()
export class WarehouseSubSegmentAdapter implements ApiAdapter<WarehouseSubSegment> {

    constructor(
        private subSegmentAdapter: SubSegmentAdapter
    ) {
    }

    public fromApi(warehouseSegment: any) {
        return {
            guid: warehouseSegment.guid,
            email: warehouseSegment.email,
            phone_number: warehouseSegment.phoneNumber,
            sub_segment: this.subSegmentAdapter.fromApi(warehouseSegment.subSegment),
        };
    }
}
import {Component, Inject, OnInit} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {environment} from "../../environments/environment";
import {User} from "../shared/models/user.model";
import {SharedApiService} from "../shared/services/api.service";
import {AuthenticationState} from "../shared/store/auth/auth.model";
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {Alerts} from "./models/alert.model";

@Component(
        {
            templateUrl: './container.component.html',
            styleUrls: [
                './container.component.scss'
            ],
        }
)
export class ContainerComponent implements OnInit {
    public user?: User;
    public alerts: Alerts = [];
    public isProduction: boolean = environment.production;
    public envName: string = environment.name || 'test';

    constructor(
            public router: Router,
            public apiService: SharedApiService,
            public store: Store<{ auth: AuthenticationState }>,
            private translateService: TranslateService,
            private route: ActivatedRoute,
            @Inject(DOCUMENT) private document: Document
    ) {
    }

    public async ngOnInit() {
        this.route.data.subscribe(data => {
            this.alerts = data.alertsResult;
        });

        this.setDocumentLang(this.translateService.currentLang);
        this.translateService.onLangChange.subscribe((res) => {
            this.setDocumentLang(res.lang);
        });

        this.store.select('auth').subscribe((state: AuthenticationState) => {
            this.user = state.user;
        });

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    /**
     * Set document lang
     * @param lang
     * @private
     */
    private setDocumentLang(lang: string) {
        this.document.documentElement.lang = lang;
    }

}

import {Component} from "@angular/core";
import {EnvService} from "../../services/env.service";

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html'
})
export class AboutUsComponent {
    constructor(
            public envService: EnvService,
    ) {
    }
}


export abstract class StorageService {
  private storage;

  protected constructor(storage) {
    this.storage = storage;
  }

  /**
   * Set value in session storage
   */
  public set(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  /**
   * Get value from session storage
   */
  public get(key) {
    return JSON.parse(this.storage.getItem(key));
  }

  /**
   * Remove value from session storage
   */
  public remove(key) {
    this.storage.removeItem(key);
  }

  /**
   * Clear session storage
   */
  public clear() {
    this.storage.clear();
  }

  /**
   * Get all storage keys
   */
  public getAllKeys() {
    return Object.keys(this.storage);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'alert-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {
  @Input() public title: string;
  @Input() public body: string;
  @Input() public btnClose: string;
  @Input() public btnCancel: string;
  @Input() public type: string;

  constructor(
      private activeModal: NgbActiveModal,
      private translateService: TranslateService
  ) {}

  public ngOnInit() {
    if (this.title) {
      this.title = this.translateService.instant(this.title);
    }

    if (this.body) {
      this.body = this.translateService.instant(this.body);
    }

    this.btnClose = this.translateService.instant(this.btnClose ? this.btnClose : 'global.close');
    this.btnCancel = this.translateService.instant(this.btnCancel ? this.btnCancel : 'global.cancel');
  }

  public close() {
    this.activeModal.close();
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}

import {Component} from '@angular/core';
import {EnvService} from "../../services/env.service";

@Component(
        {
            selector: 'alert-about-new-system',
            templateUrl: './alert-about-new-system.component.html',
            styleUrls: ['./alert-about-new-system.component.scss']
        }
)
export class AlertAboutNewSystemComponent {
    constructor(
            public envService: EnvService
    ) {
    }
}

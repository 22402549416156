import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';
import {SharedApiService} from './api.service';
import {ArticlePrice} from '../models/article-price.model';

@Injectable({providedIn: 'root'})
export class ArticlePriceService {
    public articlePrice = new BehaviorSubject<ArticlePrice>(null);
    private priceRequestQueue: string[] = [];
    private priceQueueBuilder = new Subject();

    constructor(
            private apiService: SharedApiService
    ) {
        this.priceQueueBuilder
                .pipe(debounceTime(50))
                .subscribe(_ => this.flushQueue());
    }

    public setArticlePrice(articlePrice: ArticlePrice) {
        this.articlePrice.next(articlePrice);
    }

    /**
     * Query server for article price. The observable returned will show the correct price as soon as it is available.
     *
     * @param articleGuid
     */
    public subscribeToArticle(articleGuid: string): Observable<ArticlePrice> {
        this.addToQueue(articleGuid);
        return this.articlePrice
                .pipe(filter(articlePrice =>
                        articlePrice
                        && articleGuid === articlePrice.articleGuid
                ));
    }

    public getNettPriceInPriceUnitOfMeasure(articlePrice: ArticlePrice, quantity: number) {
        const volumePrice = this.getVolumePrice(articlePrice, quantity);

        if (volumePrice) {
            return volumePrice.nettPriceInPriceUnitOfMeasure;
        }

        return articlePrice.nettPriceInPriceUnitOfMeasure;
    }

    public getGrossPriceInPriceUnitOfMeasure(articlePrice: ArticlePrice, quantity: number) {
        const volumePrice = this.getVolumePrice(articlePrice, quantity);

        if (volumePrice) {
            return volumePrice.grossPriceInPriceUnitOfMeasure;
        }

        return articlePrice.grossPriceInPriceUnitOfMeasure;
    }

    public getNettPriceInOrderingUnitOfMeasure(articlePrice: ArticlePrice, quantity: number) {
        const volumePrice = this.getVolumePrice(articlePrice, quantity);

        if (volumePrice) {
            return volumePrice.nettPriceInOrderingUnitOfMeasure;
        }

        return articlePrice.nettPriceInOrderingUnitOfMeasure;
    }

    public getGrossPriceInOrderingUnitOfMeasure(articlePrice: ArticlePrice, quantity: number) {
        const volumePrice = this.getVolumePrice(articlePrice, quantity);

        if (volumePrice) {
            return volumePrice.grossPriceInOrderingUnitOfMeasure;
        }

        return articlePrice.grossPriceInOrderingUnitOfMeasure;
    }

    private getVolumePrice(articlePrice: ArticlePrice, quantity: number): ArticlePrice | null {
        if (articlePrice.hasVolumePrices) {
            const volumePrices = articlePrice
                    .volumePrices.filter(
                            volumePrice => volumePrice.quantity <= (quantity * (articlePrice.conversionFactor ?? 1))
                    );

            if (volumePrices.length) {
                return volumePrices.pop();
            }

            return null;
        }

        return null;
    }

    /**
     * Add article to queue in order for the price to be queried soon
     *
     * @param articleGuid
     * @private
     */
    private addToQueue(articleGuid: string): void {
        this.priceRequestQueue.push(articleGuid);
        this.priceQueueBuilder.next();
    }

    /**
     *
     * @private
     */
    private flushQueue() {
        this.apiService.getArticlePricesByGuids(this.priceRequestQueue)
                .subscribe(prices => prices.forEach(price => this.setArticlePrice(price)));

        this.priceRequestQueue = [];
    }
}

import {Injectable} from '@angular/core';
import {Alert} from "../../models/alert.model";
import {ApiAdapter} from "../../../shared/adapter/api.adapter";

@Injectable()
export class AlertAdapter implements ApiAdapter<Alert> {
    public fromApi(alert: any) {
        return {
            guid: alert.guid,
            cssClass: alert.cssClass,
            content: alert.content,
            isClosable: alert.isClosable,
            isClosed: false,
        };
    }
}
import { Injectable } from '@angular/core';
import { ApiAwareHttpClient } from '../../shared/services/api_aware_http_client';

@Injectable()
export class AppInitService {

    constructor(
        private http: ApiAwareHttpClient
    ) { }

    public Init() {
        return new Promise<void>((resolve, reject) => {
            this.http.getUser({ dispatchError: false, spinner: false }).subscribe(
                result => {
                    // Success the user session is still alive.
                    resolve()
                },
                error => {
                    // User has no alive session, no problem
                    resolve()
                },
            )
        });
    }
}

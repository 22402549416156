import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import {ViewParams} from '../../models/view-params.model';
import {View} from '../../models/view.model';
import {ViewColumn} from '../../models/view-column.model';
import { saveAs } from 'file-saver';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChangeColumnComponent} from './change-column/change-column.component';
import {ViewAction} from '../../models/view-action.model';
import { LocalStorageService } from 'src/modules/shared/services/localstorage.service';
import { SessionStorageService } from 'src/modules/shared/services/session.service';
import {TableBodyRow} from '../../models/table-body-row.model';
import {SharedApiService} from '../../../shared/services/api.service';

@Component({
  selector: 'view-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  @Input() public hasTableHeader = true;
  @Input() public hasExtraOptions = true;
  @Input() public hasPagination = true;
  @Input() public hasCheckboxes = true;
  @Input() public hasSearch = true;
  @Input() public viewGuid: string;
  @Input() public showFilters = false;
  @Input() public saveViewParams = true;
  @Input() public placeholders = {};
  @Input() public viewActions: ViewAction[] = [];
  @Input() public headerTopTemplate: TemplateRef<any>;
  @Input() public headerLeftTemplate: TemplateRef<any>;
  @Input() public headerRightTemplate: TemplateRef<any>;
  @Input() public footerTemplate: TemplateRef<any>;
  @Input() public lowerFooterTemplate: TemplateRef<any>;
  @Input() public noRecordsMessage: string;
  @Input() public tableClasses: string[] = ['c-table', 'cmb', 'table-hover', 'table-fixed'];
  @Output() public rowClicked = new EventEmitter<{view: View, bodyRow: TableBodyRow}>();
  @Output() public eventFired = new EventEmitter<{view: View, viewComponent: ViewComponent}>();

  public view: View;
  private columns: ViewColumn[];
  private simpleFilters = {}; // Key/value object containing the simple filter for each column. Could not be fixed with getter function in ngModel because of infinite loop (error in Angular)
  public resultsFirst: number;
  public resultsLast: number;

  public get rowClickedEventActive() : boolean {
      return this.rowClicked.observers.length > 0;
  }

  constructor(
      private apiService: SharedApiService,
      private modalService: NgbModal,
      private localStorageService: LocalStorageService,
      private sessionStorageService: SessionStorageService
  ) {
  }

  /**
   * Submit view when opening
   */
  public ngOnInit() {
    this.submit();
  }

  /**
   * Add placeholder
   */
  public addPlaceholder(key: string, value: any) {
    this.view.params.placeholders[key] = value;
  }

  /**
   * Remove placeholder
   */
  public removePlaceholder(key: string) {
    if (this.view.params.placeholders[key] !== undefined) {
      delete this.view.params.placeholders[key];
    }
  }

  /**
   * Get default params
   */
  private getDefaultViewParams(): ViewParams {
    return {
      filters: [],
      globalSearch: '',
      limit: 25,
      page: 1,
      placeholders: this.placeholders,
      sortingColumn: null,
      sortingOrder: 'asc'
    };
  }


  /**
   * Sort by column
   */
  private sort(column, isSortable) {
    if (!isSortable) {
      return;
    }

    if (
        this.view.params.sortingColumn !== column ||
        (this.view.params.sortingColumn === column &&
            this.view.params.sortingOrder === 'desc')
    ) {
      this.view.params.sortingOrder = 'asc';
    } else {
      this.view.params.sortingOrder = 'desc';
    }

    this.view.params.sortingColumn = column;

    this.submit();
  }

  /**
   * Submit view data
   */
  public submit(event = null, resetPageNum = true) {
    if (event) {
      event.preventDefault();
    }

    let viewParams;
    if (this.view) {
      // Use params from view if available
      viewParams = this.view.params;
      if (resetPageNum) {
        viewParams.page = 1;
      }

    } else {
      // If no params available, then check if they are available in session storage
      if (this.saveViewParams) {
        viewParams = this.sessionStorageService.get(this.getViewSessionStorageId());
      }
      if (!viewParams) {
        // Otherwise set default view params
        viewParams = this.getDefaultViewParams();
      }
    }

    this.apiService.getView(this.viewGuid, viewParams).subscribe(response => {
        this.eventFired.emit({view: response, viewComponent: this})
      this.view = response;
      this.initialiseColumns();
      this.setSimpleFilters();

      const resultsLast = response.params.page * response.params.limit;
      this.resultsFirst = ((response.params.page - 1) * response.params.limit) + 1;
      this.resultsLast = resultsLast < response.table.totalRecords ? resultsLast : response.table.totalRecords;

      if (this.saveViewParams) {
        this.sessionStorageService.set(this.getViewSessionStorageId(), viewParams);
      }
    });
  }

  /**
   * Add simple filter
   */
  private addSimpleFilter(column, value) {
    // Add/update filter value param for columm
    const filter = this.view.params.filters.find(x => x.column === column);
    if (filter) {
      filter.value = value;
    } else {
      this.view.params.filters.push({
        column: column,
        operator: 'cn', // Seach for contains by default
        value: value
      });
    }

    this.setSimpleFilter(column, value);
  }

  /**
   * Export data to xlsx
   */
  private exportXlsx() {
    this.apiService.getViewXlsx(this.viewGuid, this.view.params).subscribe(res => {
      saveAs(res.file, res.fileName);
    });
  }

  /**
   * Set simple filter
   */
  private setSimpleFilter(column, value) {
    this.simpleFilters[column] = value;
  }

  /**
   * Initialise columns
   */
  private initialiseColumns() {
    const columns = [];

    if (this.view.table.headerRows[0] !== undefined) {
      let key;
      let values;
      let column: ViewColumn;

      // Check if columns are selected previously
      const viewColumns = this.localStorageService.get(this.getViewColumnsLocalStorageId()) || [];

      for (key in this.view.table.headerRows[0].cells) {
        if (this.view.table.headerRows[0].cells.hasOwnProperty(key)) {
          values = this.view.table.headerRows[0].cells[key];

          let isVisible = false;
          if (viewColumns.length === 0) {
            // If no column caching is available, then use default settings
            isVisible = !values.defaultHidden;
          } else {
            // Get column visibility from user setting
            isVisible = viewColumns.filter((viewColumn) => viewColumn.guid === values.guid && viewColumn.isVisible).length > 0;
          }

          column = {
            guid: values.guid,
            classes: values.classes,
            key: values.key,
            name: values.value,
            isPublic: values.isVisible,
            isEditable: values.isEditable,
            isVisible,
            isFilter: values.isFilter,
            tabFldId: values.tabFldId,
            fldTyp: values.fldTyp,
            width: values.width
          };

          columns.push(column);
        }
      }
    }

    this.columns = columns;
  }

  /**
   * Get all checked rows
   */
  public getCheckedRows() {
    return this.view.table.bodyRows.filter(row => row.checked);
  }

  /**
   * Click row
   */
  public clickRow(view: View, bodyRow: TableBodyRow, cell: ViewColumn) {
      this.rowClicked.emit({view, bodyRow});
  }

  /**
   * Get view component
   */
  public getViewComponent() {
    return this;
  }

  /**
   * Check all rows in the overview
   */
  private checkAll(ev) {
    this.view.table.bodyRows.forEach(x => (x.checked = ev.target.checked));
  }

  /**
   * Check if all rows are checked in the overview
   */
  private isAllChecked() {
    return this.view.table.bodyRows.length > 0
        ? this.view.table.bodyRows.every(row => row.checked)
        : false;
  }

  /**
   * Set simple filters
   */
  private setSimpleFilters() {
    this.simpleFilters = [];
    for (let key in this.view.params.filters) {
      this.setSimpleFilter(
          this.view.params.filters[key]['column'],
          this.view.params.filters[key]['value']
      );
    }
  }

  private changeColumns() {
    const modalRef = this.modalService.open(ChangeColumnComponent);

    modalRef.componentInstance.columns = this.columns;
    modalRef.result.then(
        response => {
          this.columns = response.columns;

          const columns = response.columns
              .map(column => {
                return {
                  guid: column.guid,
                  isPublic: column.isPublic,
                  isVisible: column.isVisible
                };
              });
          this.localStorageService.set(this.getViewColumnsLocalStorageId(), columns);
        },
        dismiss => {}
    );
  }

  /**
   * Get local storage id for view columns
   */
  private getViewColumnsLocalStorageId() {
    return 'view-columns-' + this.viewGuid;
  }

  /**
   * Get session storage id for view
   */
  private getViewSessionStorageId() {
    return 'view-' + this.viewGuid;
  }
}

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthenticationState } from '../store/auth/auth.model';
import { Store } from '@ngrx/store';
import {
    LoggedInAction,
    LoggedOutAction,
    LoggedOutNoRedirectAction
} from '../store/auth/auth.actions';
import { throwError } from 'rxjs';
import { ThrowAction } from '../store/error/error.actions';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from '../models/user.model';
import { getCookie } from '@syntec/angular-utils';
import {EnvService} from "./env.service";

const SYNTEC_APP_ID = 'sig-frontend';

export interface RequestOptions {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
    spinner?: boolean;
    spinnerWait?: number;
    dispatchError?: boolean;
}

@Injectable()
export class ApiAwareHttpClient {

    private baseUrl = '/api';

    // Extending the HttpClient through the Angular DI.
    public constructor(
        public http: HttpClient,
        private store: Store<AuthenticationState>,
        private spinnerService: NgxSpinnerService,
        private envService: EnvService
    ) {
    }


    /**
     * TODO: Not the most elegant solution, refactor
     */
    private getErrorCode(err: any): string {
        if (err.error) {
            if (err.error.error) {
                if (err.error.error.code) {
                    return String(err.error.error.code);
                }
            }
        }

        return '';
    }

    private showSpinner = () => {
        this.spinnerService.show('primary', {
            type: 'ball-clip-rotate',
            bdColor: 'rgba(51, 51, 51, 0.3)',
            color: 'white'
        });
    };

    private hideSpinner = () => {
        setTimeout(() => {
            this.spinnerService.hide('primary')
        }, 500)
    };

    /**
     * Conditionally show a loading spinner based on extended requestOptions
     */
    private spin = (options?: RequestOptions) => {
        let shouldSpin = true
        let spinTimeout = 500;
        if (options) {
            if (options.spinner !== undefined) {
                shouldSpin = options.spinner
            }
            if (options.spinnerWait !== undefined) {
                spinTimeout = options.spinnerWait
            }
        }

        const timer = shouldSpin ? setTimeout(() => {
            this.showSpinner()
        }, spinTimeout) : null

        return () => {
            clearTimeout(timer)
            this.hideSpinner()
        }
    };

    /**
     * Dispatch a throw action (this usually shows an alert dialog) based on
     * extended RequestOptions
     */
    public catchError = (options?: RequestOptions) => {
        const dispatchError = (options !== undefined)
            ? (options.dispatchError !== undefined ? options.dispatchError : true)
            : true

        this.hideSpinner();

        return (err: HttpErrorResponse) => {
            if (dispatchError) {
                this.store.dispatch(new ThrowAction({
                    code: this.getErrorCode(err),
                    message: err.message,
                    originalError: err
                }));
            }
            return throwError(err);
        }
    };

    public get<T>(endPoint: string, options?: RequestOptions): Observable<T> {
        options = { withCredentials: true, ...options }
        options = this.appendAppIdHeader(options);
        options = this.appendCSRFTokenHeader(options);
        options = this.appendLanguageHeader(options);

        return this.http.get<T>(this.baseUrl + endPoint, options).pipe(
            tap(this.spin(options)),
            catchError(this.catchError(options))
        );
    }

    public post<T>(endPoint: string, params: object, options?: RequestOptions): Observable<T> {
        options = { withCredentials: true, ...options }
        options = this.appendAppIdHeader(options);
        options = this.appendCSRFTokenHeader(options);
        options = this.appendLanguageHeader(options);

        return this.http.post<T>(this.baseUrl + endPoint, params, options).pipe(
            tap(this.spin(options)),
            catchError(this.catchError(options))
        );
    }

    public put<T>(endPoint: string, params: object, options?: RequestOptions): Observable<T> {
        options = { withCredentials: true, ...options }
        options = this.appendAppIdHeader(options);
        options = this.appendCSRFTokenHeader(options);
        options = this.appendLanguageHeader(options);

        return this.http.put<T>(this.baseUrl + endPoint, params, options).pipe(
            tap(this.spin(options)),
            catchError(this.catchError(options))
        );
    }

    public patch<T>(endPoint: string, params: object, options?: RequestOptions): Observable<T> {
        options = { withCredentials: true, ...options }
        options = this.appendAppIdHeader(options);
        options = this.appendCSRFTokenHeader(options);
        options = this.appendLanguageHeader(options);

        return this.http.patch<T>(this.baseUrl + endPoint, params, options).pipe(
            tap(this.spin(options)),
            catchError(this.catchError(options))
        );
    }

    public delete<T>(endPoint: string, options?: RequestOptions): Observable<T> {
        options = { withCredentials: true, ...options }
        options = this.appendAppIdHeader(options);
        options = this.appendCSRFTokenHeader(options);
        options = this.appendLanguageHeader(options);

        return this.http.delete<T>(this.baseUrl + endPoint, options).pipe(
            tap(this.spin(options)),
            catchError(this.catchError(options))
        );
    }

    public login(username: string, password: string, options?: RequestOptions): Observable<User> {
        options = this.appendHeader(options, 'Authorization', 'Basic ' + btoa(`${username}:${password}`));
        options = this.appendAppIdHeader(options);

        return this.getUser(options);
    }

    public logout(redirectToLogin: boolean, options?: RequestOptions): Observable<any> {
        options = this.appendCSRFTokenHeader(options);

        return this.http.get(this.baseUrl + '/core/auth/logout', options).pipe(
            tap(() => {
                this.store.dispatch(
                    redirectToLogin ? new LoggedOutAction() : new LoggedOutNoRedirectAction()
                );
            }),
            catchError(this.catchError(options))
        )
    }

    /**
     * Adds the authorization header if applicable
     */
    private appendAppIdHeader(options?: RequestOptions): RequestOptions {
        return this.appendHeader(options, 'X-Syntec-App-ID', SYNTEC_APP_ID);
    }

    private appendCSRFTokenHeader(options?: RequestOptions): RequestOptions {
        const csrfToken = getCookie('csrfcookie');
        return this.appendHeader(options, 'X-CSRF-Token', csrfToken);
    }

    private appendLanguageHeader(options?: RequestOptions): RequestOptions {
        return this.appendHeader(
                options,
                'x-language',
                this.determineLocale(localStorage.getItem('locale') || this.envService.defaultLocale)
        );
    }

    public getUser(options: RequestOptions): Observable<User> {
        options = { withCredentials: true, ...options }
        options = this.appendCSRFTokenHeader(options);

        return this.http.get(this.baseUrl + '/core/user', options).pipe(
            tap((user: User) => {
                this.store.dispatch(new LoggedInAction(user));
            }),
            map(x => {
                return x as User;
            }),
            catchError(this.catchError(options))
        )
    }


    /**
     * Appends a header to RequestOptions, when null is passed as argument
     * a new RequestOptions object will be created
     */
    private appendHeader(options: RequestOptions | null, headerKey: string, headerValue: string): RequestOptions {
        if (!options) {
            options = {
                headers: new HttpHeaders()
            };
        }

        if (!options.headers) {
            options.headers = new HttpHeaders();
        }

        options.headers = options.headers.append(headerKey, headerValue);

        return options;
    }

    private determineLocale(locale: string): string {
        if (locale === 'nl-NL') {
            return 'nl';
        }

        return locale
    }
}

import {Injectable} from '@angular/core';
import {ApiAdapter} from "../../shared/adapter/api.adapter";
import {OrderAddress} from "../models/OrderAddress.model";

@Injectable()
export class OrderAddressAdapter implements ApiAdapter<OrderAddress> {

    constructor() {
    }

    public fromApi(orderAddress: any) {
        return {
            guid: orderAddress.guid,
            name: orderAddress.name,
            street: orderAddress.street,
            house_number: orderAddress.houseNumber,
            house_number_suffix: orderAddress.houseNumberSuffix,
            zip_code: orderAddress.zipCode,
            city: orderAddress.city,
        };
    }
}

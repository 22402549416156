import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FormQuestionComponent} from "./components/form-question/form-question.component";
import {RecordResolver} from "./resolvers/record.resolver";
import {FormGuidEnum} from "./enums/form-guid.enum";

const routes: Routes = [
    {
        path: 'question',
        component: FormQuestionComponent,
        resolve: {
            record: RecordResolver
        },
        data: {
            formGuid: FormGuidEnum.QUESTION
        }
    },
];

@NgModule(
    {
        imports: [
            RouterModule.forChild(routes)
        ],
        exports: [
            RouterModule
        ]
    }
)

export class SharedRoutingModule {
}

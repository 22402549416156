import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { ChangeColumnComponent } from './components/view/change-column/change-column.component';
import { RowDynamicComponentComponent } from './components/view/row-dynamic-component.component';
import { ViewComponent } from './components/view/view.component';
import { ViewRoutingModule } from './view-routing.module';
import {ClampyModule} from '@clampy-js/ngx-clampy';

@NgModule({
  declarations: [ViewComponent, RowDynamicComponentComponent, ChangeColumnComponent],
    imports: [
        CommonModule,
        ViewRoutingModule,
        SharedModule,
        FormsModule,
        NgbModule,
        ClampyModule
    ],
  providers: [
  ],
  entryComponents: [
    ChangeColumnComponent,
  ],
  exports: [
      ViewComponent
  ]
})
export class ViewModule { }

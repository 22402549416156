import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { File } from '../models/file.model';

@Injectable()
export class FileService {
  constructor() {}

  public getFile(response: HttpResponse<any>): File {
    // Get file name from header
    const contentDispositionHeader: string = response.headers.get(
      'Content-Disposition'
    );
    const parts: string[] = contentDispositionHeader.split(';');

    let fileName = '';
    if (parts.length === 2) {
      const fileParts = parts[1].split('=');

      if (fileParts.length === 2) {
        fileName = fileParts[1].replace(/"/g, '');
      }
    }

    return {
      fileName: fileName,
      file: new Blob([response.body])
    };
  }
}

import {Injectable} from '@angular/core';
import {formatCurrency, formatPercent, formatDate, formatNumber} from '@angular/common';

@Injectable()
export class FormatterService {
    public constructor() {
    }

    public formatCurrency(value: number, locale = 'nl', currency = '€'): string {
        return isNaN(value) ? '' : formatCurrency(value, locale, currency);
    }

    public formatNumber(value: number, locale = 'nl'): string {
        return isNaN(value) ? '' : formatNumber(value, locale);
    }

    public formatPercentage(value: number, locale = 'nl'): string {
        return isNaN(value) ? '' : formatPercent(value, locale);
    }

    public formatDate(value: string | number | Date, locale = 'nl'): string {
        return formatDate(value, 'dd-MM-yyyy', locale);
    }

    public formatDateAndTime(value: string | number | Date, locale = 'nl'): string {
        return formatDate(value, 'dd-MM-yyyy HH:mm', locale);
    }
}

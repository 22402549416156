import {ApiAdapter} from "./api.adapter";
import {Injectable} from '@angular/core';
import {castToArray} from 'src/modules/shared/util/array';
import {Carousel} from "../models/carousel.model ";
import {CarouselItem} from "../models/carousel-item.model ";

@Injectable()
export class CarouselAdapter implements ApiAdapter<Carousel> {

    public fromApi(carousel: any) {
        if (carousel === null) {
            return {cid: null, items: []};
        }

        return {
            cid: carousel.cid,
            items: (castToArray(carousel.items) || [])
                .map((item: any) => mapItemFromApi(item)),
        };
    }
}

function mapItemFromApi(item: any): CarouselItem {
    return {
        title: item.title,
        link: item.link,
        content: item.content,
        image: item.image,
        path: item.path
    };
}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {EMPTY, Observable} from 'rxjs';
import {SharedApiService} from '../services/api.service';
import {Record} from '../models/record.model';

@Injectable()
export class RecordResolver implements Resolve<any> {
    /**
     * Constructor
     */
    constructor(
        private apiService: SharedApiService
    ) {}

    /**
     * Resolve record before displaying
     */
    public resolve(route: ActivatedRouteSnapshot): Observable<Record> {
        const formGuid = route.data['formGuid'];
        if (!formGuid) {
            return EMPTY;
        }

        return this.apiService.getRecord(formGuid, route.params.guid || null);
    }
}

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
  ) {}

  // Intercept request
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.loaderService.show();

    return next.handle(request).pipe(
      finalize(() => {
        this.loaderService.hide();
      })
    );
  }
}

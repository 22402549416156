import {Component, ElementRef, OnInit, ViewChild, AfterViewInit, Renderer2, Input} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {NgbDropdown, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {CatalogueLink, CatalogueLinks} from 'src/modules/app/models/cataloguelink.model';
import {User} from 'src/modules/shared/models/user.model';
import {ApiAwareHttpClient} from 'src/modules/shared/services/api_aware_http_client';
import {AuthenticationState} from 'src/modules/shared/store/auth/auth.model';
import {SharedApiService} from '../../../shared/services/api.service';
import {TranslateService} from '@ngx-translate/core';
import {MenuItem, MenuService} from 'src/modules/shared/services/menu.service';
import {AuthService} from '../../../shared/services/auth.service';
import {ShoppingCartCount} from "../../../shared/models/shopping-cart-count";
import {ShoppingCartCountService} from "../../../shared/services/shopping_cart_count";
import {filter} from "rxjs/operators";
import {animate, style, transition, trigger} from '@angular/animations';
import {forkJoin} from 'rxjs';
import {LoggedInAction} from "../../../shared/store/auth/auth.actions"
import {EnvService} from "../../../shared/services/env.service";
import {slugify} from "../../../shared/util/slugify";
import {Alerts} from "../../models/alert.model";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],

    animations: [
        trigger('cardFromLeft', [
            transition(':enter', [
                animate(0, style({transform: 'translateX(-100%)'})),
                animate('0.25s', style({transform: 'translateX(0%)'}))
            ]),
            transition(':leave', [
                animate('0.25s', style({transform: 'translateX(-100%)'}))
            ]),
        ]),
        trigger('cardFromRight', [
            transition(':enter', [
                animate(0, style({transform: 'translateX(100%)'})),
                animate('0.25s', style({transform: 'translateX(0%)'}))
            ]),
            transition(':leave', [
                animate('0.25s', style({transform: 'translateX(100%)'}))
            ]),
        ]),
    ]
})
export class HeaderComponent implements OnInit, AfterViewInit {

    @ViewChild('departmentDropdown') public departmentDropdown: NgbDropdown;
    @ViewChild('searchDepartmentTypeahead', {static: true}) private searchDepartmentTypeahead: NgbTypeahead;
    @ViewChild('searchDepartmentElement') private searchDepartmentElement: ElementRef;

    @Input() public alerts: Alerts = [];

    public user?: User;
    public q: string;
    public shoppingCartCount: ShoppingCartCount;
    public menu: MenuItem[];
    public organisations: any;
    public showOrganisationSwitch: boolean = false;

    public finishHierarchy: CatalogueLink;
    public technicalInsulationHierarchy: CatalogueLink;

    public menuOpened: boolean = false;
    public levelZeroHierarchy?: CatalogueLink;
    public levelOneHierarchy: CatalogueLink;
    public levelTwoHierarchy: CatalogueLink;
    public levelThreeHierarchy: CatalogueLink;

    public showPriceUpdates: boolean = false;

    public showSwitchComponent: boolean = false;

    constructor(
            private el: ElementRef,
            private renderer: Renderer2,
            public apiClient: ApiAwareHttpClient,
            public apiService: SharedApiService,
            public store: Store<{ auth: AuthenticationState }>,
            public authService: AuthService,
            public envService: EnvService,
            private router: Router,
            private translateService: TranslateService,
            private menuService: MenuService,
            private shoppingCartCountService: ShoppingCartCountService,
            private appService: ApiAwareHttpClient
    ) {
    }

    ngAfterViewInit() {
        this.setHeaderHeight();
        window.addEventListener('resize', this.setHeaderHeight.bind(this));
        const observer = new MutationObserver(this.setHeaderHeight.bind(this));
        observer.observe(this.el.nativeElement, {attributes: true});
    }

    setHeaderHeight() {
        const header = this.el.nativeElement.querySelector('.site-header');
        if (header) {
            const height = header.offsetHeight;
            if (height) {
                document.documentElement.style.setProperty('--header-height', `${height}px`);
            }
        }
    }

    public ngOnInit() {
        this.router.events.pipe(filter(navigationEnd => navigationEnd instanceof NavigationEnd)).subscribe((navigationEnd: any) => {
            if (!navigationEnd.url.startsWith('/catalogue')) {
                this.q = null;
            }
        });

        this.router.events.pipe(filter(navigationEnd => navigationEnd instanceof NavigationEnd)).subscribe((navigationEnd: any) => {
            const urlParams = new URLSearchParams(navigationEnd.url);
            const queryParam = urlParams.get('/catalogue?q');

            if (queryParam && this.q !== queryParam) {
                this.q = queryParam;
            }
        });

        forkJoin({
            finishHierarchy: this.appService.get(`/sig/header/hierarchy/1`),
            technicalInsulationHierarchy: this.appService.get(`/sig/header/hierarchy/2`),
        }).subscribe((data: any) => {
            this.technicalInsulationHierarchy = data.technicalInsulationHierarchy;
            this.finishHierarchy = data.finishHierarchy;

            if ((this.authService.hasFinishSegment() || this.envService.companyName === 'MPA')
                    && this.finishHierarchy) {
                this.levelZeroHierarchy = null;
                this.levelOneHierarchy = this.finishHierarchy;
            } else if ((this.authService.hasTechnicalInsulationSegment() || this.envService.companyName === 'Isolatec')
                    && this.technicalInsulationHierarchy) {
                this.levelZeroHierarchy = null;
                this.levelOneHierarchy = this.technicalInsulationHierarchy;
            } else {
                this.levelZeroHierarchy = {
                    hierarchies: [
                        this.finishHierarchy,
                        this.technicalInsulationHierarchy
                    ].filter((catalogueLink: CatalogueLink) => catalogueLink !== null),
                    guid: '',
                    name: '',
                    cid: '',
                    productCount: 0
                } as CatalogueLink;
                this.levelOneHierarchy = null;
            }
        });

        this.store.select('auth').subscribe((state: AuthenticationState) => {
            this.user = state.user;
            if (state.user) {
                this.apiService.getOrganisations().subscribe(response => {
                    this.organisations = response;
                });

                this.shoppingCartCountService.updateCount();

                this.menu = this.menuService.getMenu();

                this.apiService.getHasPriceUpdates().subscribe((response => {
                    this.showPriceUpdates = response;
                }));
            }

            if (this.user && this.user.originalUser !== null) {
                this.showSwitchComponent = true;
            }
        });

        this.shoppingCartCountService.count$.subscribe((shoppingCartCount: ShoppingCartCount) => {
            this.shoppingCartCount = shoppingCartCount;
        });

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    public logoutClicked = () => {
        this.apiClient.logout(true).subscribe();
    };

    public productGroupLinkClicked(dropdown: NgbDropdown, link: CatalogueLink) {
        dropdown.close();

        let urlParams = new URLSearchParams();
        urlParams.append('hierarchy[]', link.guid);

        urlParams.append('force_switch', '1');

        this.router.navigateByUrl('/catalogue?' + urlParams.toString()).then();
    }

    public hierarchyClick(link: CatalogueLink) {
        if (this.envService.enterpriseSearchAppSearchEnabled) {
            let filters = [];
            if (this.levelOneHierarchy) {
                filters['filters[product_hierarchy_level_1][]'] = this.levelOneHierarchy.name;
            }
            if (this.levelTwoHierarchy) {
                filters['filters[product_hierarchy_level_2][]'] = this.levelTwoHierarchy.name;
            }
            if (this.levelThreeHierarchy) {
                filters['filters[product_hierarchy_level_3][]'] = this.levelThreeHierarchy.name;
            }
            if (link) {
                filters['filters[product_hierarchy_level_4][]'] = link.name;
            }

            this.router.navigate(['/catalogue', 'search'], {queryParams: filters}).then();
        } else {
            // force close menu
            this.menuOpened = false;
            if (this.levelZeroHierarchy) {
                this.levelOneHierarchy = null;
            }
            this.levelTwoHierarchy = null;
            this.levelThreeHierarchy = null;

            let urlParams = new URLSearchParams();
            urlParams.append('hierarchy[]', link.guid);

            urlParams.append('force_switch', '1');

            this.router.navigateByUrl('/catalogue?' + urlParams.toString()).then();
        }
    }

    // Show/hide search bar on smaller viewports
    public toggleSearchBar() {
        if (document.body.classList.contains('menu-search-open')) {
            document.body.classList.remove('menu-search-open');
        } else {
            document.body.classList.add('menu-search-open');
        }
    }

    public back(e) {
        const parent = e.target.closest(".mobile-menu-list-wrapper");
        parent.classList.remove('is-visible');
        parent.previousElementSibling
                .querySelector(".is-active")
                .classList.remove('is-active');
    }

    // Hide sidebar menu on smaller viewports
    public closeResponsiveMenu() {
        document.body.classList.remove('menu-dropdown-open');
    }

    public selectOrganisation(sigPartnerOrganisationGuid: string) {
        this.apiService.setSigPartnerOrganisation(sigPartnerOrganisationGuid).subscribe(() => {
            this.store.dispatch(new LoggedInAction(this.user));

            this.showOrganisationSwitch = false;

            this.router.navigate([this.router.url]).then();
        });
    }

    public acknowledgePriceUpdates() {
        if (this.user) {
            this.apiService.acknowledgePriceUpdates().subscribe(() => {
                this.showPriceUpdates = false;
            });
        }
    }

    public getHeaderMenuHierarchies(hierarchies: CatalogueLinks): CatalogueLinks {
        return hierarchies.filter((hierarchy: CatalogueLink) => {
            return hierarchy.showInHeaderMenu;
        });
    }

    public getHierarchyName(hierarchy: CatalogueLink): string {
        if (this.authService.hasFinishSegment()
                || this.authService.hasTechnicalInsulationSegment()
                || ['MPA', 'Isolatec'].includes(this.envService.companyName)) {
            return this.translateService.instant('page.header.catalogue.label');
        }

        return hierarchy.name;
    }

    public slug(name: string) {
        return slugify(name);
    }

    public getAlerts() {
        return this.alerts.filter(alert => !alert.isClosed);
    }

    public submitQuery(query: string) {
        this.router.navigate(['/catalogue', 'search'], {queryParams: {query: query}}).then();
    }
}

import {Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';


// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

    constructor(private translateService: TranslateService) {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        return this.translateService.instant('datepicker.weekday.' + (weekday - 1))
    }
    getMonthShortName(month: number): string {
        return this.translateService.instant('datepicker.month.' + (month - 1))
    }
    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}

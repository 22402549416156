import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { ERROR_THROWN } from './error.actions';
import { Error } from './error.model';
import { AlertService } from '../../services/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationState } from '../auth/auth.model';
import { Store } from '@ngrx/store';
import { UnauthorizedAction } from '../auth/auth.actions';
import { CoreErrors } from './errors';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class ErrorEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private logger: NGXLogger,
        private store: Store<AuthenticationState>
    ) {
    }

    @Effect({dispatch: false})
    public onError$ = this.actions$.pipe(
        ofType(ERROR_THROWN),
        map((action: any) => action.payload),
        tap(this.handleError.bind(this))
    );

    public handleError(err: Error) {
        // Handle HttpErrorResponses
        if (err.originalError && isHttpErrorResponse(err.originalError)) {

            // Handle specific errors.
            if (err.code === CoreErrors.NOT_FOUND) {
                this.router.navigate(['404']);
                return;
            }

            // Show translated alert for known errors.
            const packageName = err.code.split('-')[0];
            switch (packageName) {
                case 'CORE':
                    const code = err.code.toLowerCase();

                    const keyBody = `error.${code}.body`;
                    const body = this.translateService.instant(keyBody);

                    const keyTitle = `error.${code}.title`;
                    const title = this.translateService.instant(keyTitle);

                    if (keyBody !== body) {
                        this.alertService.alert(body, {title});
                        return;
                    }
            }

            // Unauthorized specific error handling
            if (err.originalError.status === 401) {
                this.store.dispatch(new UnauthorizedAction());
                return;
            } else if (err.originalError.status === 409) {
                this.alertService.alert(err.originalError.error.error.message, {title: 'error.409.title'});
                return;
            } else if (err.originalError.status >= 400) {
                this.alertService.alert('error.gt400.body', {title: 'error.gt400.title'});
                return;
            }
        }

        // TODO(rharink): swallow unknown errors? they are logged to the console.
    }
}

function isHttpErrorResponse(obj: any): obj is HttpErrorResponse {
    return obj.status !== undefined && obj.statusText !== undefined;
}

import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SyntecMissingTranslationHandler} from './missingtranslationshandler';
import {ApiAwareHttpClient} from '../services/api_aware_http_client';
import {SyntecTranslateLoader} from './translateloader.service';

export function importTranslateModule() {
    return TranslateModule.forRoot({
        missingTranslationHandler: {
            provide: MissingTranslationHandler,
            useClass: SyntecMissingTranslationHandler
        },
        loader: {
            provide: TranslateLoader,
            useClass: SyntecTranslateLoader,
            deps: [
                ApiAwareHttpClient
            ]
        }
    });
}

import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewColumn} from '../../../models/view-column.model';

/**
 * Component to display view in modal
 */
@Component({
  templateUrl: './change-column.component.html',
  styleUrls: ['./change-column.component.scss']
})
export class ChangeColumnComponent implements OnInit {

  @Input() public columns: ViewColumn[];

  /**
   * Constructor
   */
  constructor(private activeModal: NgbActiveModal) {}

  public ngOnInit() {
    // Make copy of object array, otherwise changes are reflected in frontend
    this.columns = this.columns.map(column => ({...column}));
  }

  /**
   * Save modal
   */
  public save(): void {
    this.activeModal.close({columns: this.columns});
  }

  /**
   * Cancel modal
   */
  public cancelModal(): void {
    this.activeModal.dismiss();
  }

  /**
   * Check if at least one column is checked
   */
  public hasCheckedColumns(): boolean {
    return this.columns.some(column => column.isVisible);
  }
}

import {Component} from "@angular/core";
import {EnvService} from "../../services/env.service";

@Component({
    selector: 'app-nearby',
    templateUrl: './nearby.component.html'
})
export class NearbyComponent {
    constructor(
            public envService: EnvService,
    ) {
    }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shared-field-validation',
  templateUrl: './field-validation.component.html'
})
export class FieldValidationComponent implements OnInit {

  @Input() public isVisible = true;
  @Input() public message;

  constructor(
  ) {}

  public ngOnInit() {
  }
}

import { Injectable } from '@angular/core';
import { ApiAdapter } from "../../shared/adapter/api.adapter";
import { OrderServiceLine } from '../models/orderServiceLine.model';

@Injectable()
export class OrderServiceLineAdapter implements ApiAdapter<OrderServiceLine> {

    constructor() {
    }

    public fromApi(serviceLine: any) {
        return {
            guid: serviceLine.guid,
            price: serviceLine.price,
            quantity: serviceLine.quantity,
            type_cid: serviceLine.typeCid,
            type_name: serviceLine.typeName
        };
    }
}

import { AuthenticationState } from './auth.model';
import {
    AuthenticationActions,
    REFRESHED_TOKENS,
    LOGGED_IN,
    LOGGED_OUT,
    UNAUTHORIZED,
    LOGGED_OUT_NO_REDIRECT
} from './auth.actions';

const initialState: AuthenticationState = {
    user: null,
};

export function AuthenticationReducer(
    state: AuthenticationState = initialState,
    action: AuthenticationActions
): AuthenticationState {
    switch (action.type) {
        case REFRESHED_TOKENS:
        case LOGGED_IN:
            return { user: action.payload };
        case LOGGED_OUT:
        case LOGGED_OUT_NO_REDIRECT:
        case UNAUTHORIZED:
            return initialState;
        default:
            return state;
    }
}

import {ApiAdapter} from '../../shared/adapter/api.adapter';
import {Injectable} from '@angular/core';
import {slugify} from 'src/modules/shared/util/slugify';
import {Article} from "../models/article.model";
import {castToArray} from "../../shared/util/array";
import {RemoteFile} from "../models/remotefile.model";
import {CatalogueLink} from "../../app/models/cataloguelink.model";

@Injectable()
export class ArticleAdapter implements ApiAdapter<Article> {
    public fromApi(article: any) {
        return {
            slug: slugify(article.name),
            guid: article.guid,
            cid: article.cid,
            name: article.name,
            isCommission: article.isCommission,
            description: article.description,
            minimumOrderQuantity: article.minimumOrderQuantity ? parseFloat(article.minimumOrderQuantity) : null,
            quantityMultiplier: article.quantityMultiplier ? parseFloat(article.quantityMultiplier) : null,
            articleAttributes: castToArray(article.articleAttributes || [])
                    .map(articleAttribute => {
                        return {
                            guid: articleAttribute.guid,
                            type: articleAttribute.type,
                            value: articleAttribute.value,
                        };
                    }),
            images: castToArray(article.images || [])
                    .map(image => {
                        return {
                            src: image.image,
                            thumb: image.image,
                            caption: image.name,
                        };
                    }),
            videos: castToArray(article.videos || [])
                    .map(video => {
                        return {
                            youtubeIdentifier: video.youtubeIdentifier,
                            name: video.name,
                        };
                    }),
            mainImage: article.mainImage,
            files: (castToArray(article.files) || [])
                    .map((file: any) => mapFileFromApi(file)),
            stock: null,
            inAssortment: false,
            product: article.product ? {
                id: article.product.id,
                slug: slugify(article.product.name),
                guid: article.product.guid,
                name: article.product.name,
                description: article.product.description,
                productArticles: [],
                images: [],
                videos: [],
                relatedProducts: [],
                files: [],
                mainImage: article.product.mainImage,
                hierarchies: castToArray(article.product.hierarchies || [])
                        .map((catalogueLink: CatalogueLink) => {
                            return {
                                guid: catalogueLink.guid,
                                icon: catalogueLink.icon,
                                sorter: catalogueLink.sorter,
                                hierarchies: catalogueLink.hierarchies,
                                name: catalogueLink.name,
                                cid: catalogueLink.cid,
                                productCount: catalogueLink.productCount,
                            };
                        }),
            } : null,
            hasVolumePrices: false,
            stockLocations: [],
        };
    }
}

function mapFileFromApi(file: any): RemoteFile {

    let icon = 'fa-file-alt';

    if (file.type && file.type.icon) {
        icon = file.type.icon;
    }

    return {
        name: file.name,
        url: file.url,
        icon: icon,
        file: {
            name: file.file.name,
        }
    };
}

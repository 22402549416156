import {Injectable} from '@angular/core';
import {ApiAdapter} from "./api.adapter";
import {Address} from "../models/address.model";

@Injectable()
export class AddressAdapter implements ApiAdapter<Address> {

    constructor() {
    }

    public fromApi(address: any) {
        const crmPartnerAddress = {
            guid: address.crmPartnerAddress.guid,
            is_default_address: address.crmPartnerAddress.isDefaultAddress,
            street: address.crmPartnerAddress.street,
            house_number: address.crmPartnerAddress.houseNumber,
            house_number_suffix: address.crmPartnerAddress.houseNumberSuffix,
            zipcode: address.crmPartnerAddress.zipcode,
            city: address.crmPartnerAddress.city,
            region: address.crmPartnerAddress.region,
            building: address.crmPartnerAddress.building,
            floor: address.crmPartnerAddress.floor,
            phone: address.crmPartnerAddress.phone,
            fax: address.crmPartnerAddress.fax,
            email: address.crmPartnerAddress.email,
            type: address.crmPartnerAddress.type ? {
                guid: address.crmPartnerAddress.type.guid,
                cid: address.crmPartnerAddress.type.cid,
                name: address.crmPartnerAddress.type.name,
            } : null,
        };

        return {
            guid: address.guid,
            cid: address.cid,
            crm_partner_address: crmPartnerAddress,
        };
    }
}

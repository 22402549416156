import {Injectable} from "@angular/core";
import {ReplaySubject} from 'rxjs';
import {AuthService} from './auth.service';
import {ShoppingCartCount} from "../models/shopping-cart-count";
import {SharedApiService} from "./api.service";

@Injectable()
export class ShoppingCartCountService {
    public count$: ReplaySubject<ShoppingCartCount>

    constructor(
        private apiService: SharedApiService,
        private authService: AuthService
    ) {
        this.count$ = new ReplaySubject<ShoppingCartCount>();
    }

    public updateCount() {
        if (!this.authService.hasRoleOrderer()) {
            return;
        }

        this.apiService.getShoppingCartCount().subscribe((shoppingCartCount) => {
            this.count$.next(shoppingCartCount);
        })
    }
}

import {Component, OnInit} from "@angular/core";
import {User} from "../../models/user.model";
import {Store} from "@ngrx/store";
import {AuthenticationState} from "../../store/auth/auth.model";

@Component({
    selector: 'app-form-links',
    templateUrl: './form-links.component.html'
})
export class FormLinksComponent implements OnInit {

    public user?: User;

    constructor(public store: Store<{ auth: AuthenticationState }>) {

    }

    public ngOnInit(): void {
        this.store.select('auth').subscribe((state: AuthenticationState) => {
            this.user = state.user;
        });
    }
}

import {Injectable} from '@angular/core';
import {ApiAdapter} from "./api.adapter";
import {Segment} from "../models/segment.model";

@Injectable()
export class SegmentAdapter implements ApiAdapter<Segment> {
    public fromApi(segment: any) {
        return {
            guid: segment.guid,
            cid: segment.cid,
            name: segment.name
        };
    }
}
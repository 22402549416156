import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CatalogueLink } from '../../models/cataloguelink.model';

@Component({
    selector: 'app-mobile-menu-card',
    templateUrl: './mobile-menu-card.component.html',
    styleUrls: ['./mobile-menu-card.component.scss']
})
export class MobileMenuCardComponent {
    @Input() showBackButton: boolean = true;
    @Input() hierarchy: CatalogueLink;
    @Output() backPressed: EventEmitter<void> = new EventEmitter<void>();
    @Output() itemSelected: EventEmitter<CatalogueLink> = new EventEmitter<CatalogueLink>();
}

/**
 * Converts a list like structure to a certain list.
 * 
 * This is introduced because of the following bug:
 * 
 * BUG: JMSSerializer serializing a json array using {} instead of []
 * 
 * I don't know why but thr API sometime returns an array like [0 => {}, 1 => {}] instead of [{}, {}].
 *
 * @see https://github.com/schmittjoh/JMSSerializerBundle/issues/373
 */
export function castToArray(x: object | Array<any>): Array<any> {

    if (x instanceof Array) {
        return x;
    }

    const out = [];

    for (const key in x) {
        if (x.hasOwnProperty(key)) {
            out[out.length] = x[key];
        }
    }

    return out;
}


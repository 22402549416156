import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { SharedApiService } from './api.service';
import { ArticleStockByLocation } from '../models/article-stock-by-location.model';

@Injectable({providedIn: 'root'})
export class ArticleStockByLocationService {
    public articleStock = new BehaviorSubject<ArticleStockByLocation>(null);
    private stockRequestQueue: Array<string> = [];
    private stockQueueBuilder = new Subject();

    constructor(
            private apiService: SharedApiService
    ) {
        this.stockQueueBuilder
                .pipe(debounceTime(50))
                .subscribe(_ => this.flushQueue());
    }

    public setArticleStock(articleStock: ArticleStockByLocation) {
        this.articleStock.next(articleStock);
    }

    /**
     * Query server for article stock. The observable returned will show the correct stock as soon as it is available.
     *
     * @param articleGuid
     * @param locationGuid
     */
    public subscribeToArticleOnLocation(articleGuid: string, locationGuid: string): Observable<number | null> {
        return this.subscribeToArticle(articleGuid)
                .pipe(map(stock => {
                    if (locationGuid in stock.stock) {
                        return +stock.stock[locationGuid].stock;
                    }

                    return null;
                }));
    }

    /**
     * Query server for article stock. The observable returned will show the correct stock as soon as it is available.
     *
     * @param articleGuid
     */
    public subscribeToArticle(articleGuid: string): Observable<ArticleStockByLocation> {
        this.addToQueue(articleGuid);
        return this.articleStock
                .pipe(filter(articleStock =>
                        articleStock
                        && articleGuid === articleStock.articleGuid
                ));
    }

    /**
     * Add article to queue in order for the stock to be queried soon
     *
     * @param articleGuid
     * @private
     */
    private addToQueue(articleGuid: string): void {
        this.stockRequestQueue.push(articleGuid);
        this.stockQueueBuilder.next();
    }

    /**
     *
     * @private
     */
    private flushQueue() {
        this.apiService.getArticleStockForMultipleLocationsByGuids(this.stockRequestQueue)
                .subscribe(stocks => stocks.forEach(stock => this.setArticleStock(stock)));

        this.stockRequestQueue = [];
    }

}

import {Injectable} from '@angular/core';
import {ApiAdapter} from "./api.adapter";
import {Gender} from "../models/gender.model";

@Injectable()
export class GenderAdapter implements ApiAdapter<Gender> {
    public fromApi(gender: any) {
        return {
            guid: gender.guid,
            cid: gender.cid,
            name: gender.name,
        };
    }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';

export interface MenuItem {
    title: string;
    description?: string;
    icon: string;
    isAllowed: boolean;
    action: () => void;
}

@Injectable()
export class MenuService {
    /**
     * Constructor
     */
    public constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    /**
     * Get menu
     */
    public getMenu(): MenuItem[] {
        let menu = this.getMenuItems();

        return menu.filter((item: MenuItem) => {
            return item.isAllowed;
        });
    }

    /**
     * Navigate to route
     */
    private navigateTo(n: string[]): () => void {
        return () => {
            this.router.navigate(n);
        };
    }

    /**
     * Get list of menu items. Do not set as property, because then the authService will not have the roles loaded
     */
    private getMenuItems(): MenuItem[] {
        return [
            {
                title: 'menu.startpage.title',
                icon: 'fal fa-home',
                action: this.navigateTo(['/start']),
                isAllowed: this.authService.hasRoleOrderer()
            },
            {
                title: 'menu.assortment.title',
                icon: 'fal fa-heart',
                action: this.navigateTo(['/assortment', 'list']),
                isAllowed: this.authService.hasRoleOrderer()
            },
            {
                title: 'menu.orders.title',
                icon: 'fal fa-history',
                action: this.navigateTo(['/order', 'list']),
                isAllowed: this.authService.hasRoleOrderer()
            },
            {
                title: 'menu.downloads.title',
                icon: 'fal fa-download',
                action: this.navigateTo(['/user', 'downloads']),
                isAllowed: this.authService.hasRoleOrderer()
            },
            {
                title: 'menu.personal_details.title',
                icon: 'fal fa-user',
                action: this.navigateTo(['/user', 'my_account']),
                isAllowed: true
            },
        ];
    }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe(
        {
            name: 'ucWords'
        }
)
export class UcWordsPipe implements PipeTransform {
    public transform(value: string): string {
        value = value.toLowerCase();
        return (value + '').replace(/^([a-z])|\s+([a-z])/g, ($1) => {
            return $1.toUpperCase();
        });
    }
}
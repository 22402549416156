import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../services/auth.service';

export function TranslateInitializerFactory(translateService: TranslateService, authService: AuthService) {
    return () => new Promise<any>((resolve: any) => {

        const user = authService.getUser();
        if (user) {
            // Get language of logged in user
            translateService.use(user.lang).subscribe(() => { }, err => {
                console.error(`Error with '${user.lang}' language initialization.'`);
            }, () => {
                resolve(null);
            });
        } else {
            // Check if query param 'lang' is provided, do not subscribe on ActivatedRoute, this function will trigger twice
            const paramLang = (new URLSearchParams(window.location.search)).get('lang') || '';

            // Get languages from query parap, otherwise from browser
            const languages = [...[paramLang], ...[navigator.language], ...navigator.languages];
            // Get first nl/fr language from array
            const language = languages.find((language: string) => {
                return (['nl', 'de']).indexOf(language.substr(0,2)) !== -1;
            });

            // Load language
            const lang = language && language.substr(0,2) || 'nl';
            translateService.setDefaultLang(lang);
            translateService.use(lang).subscribe(() => { }, err => {
                console.error(`Error with '${lang}' language initialization.'`);
            }, () => {
                resolve(null);
            });
        }
    });
}

/**
 * Partner assortment class
 */
export class PartnerAssortment {
    public readonly ACTION_INSERTED = 'inserted';
    public readonly ACTION_MATERIAL_INACTIVE = 'material_inactive';
    public readonly ACTION_MATERIAL_UNAVAILABLE = 'material_unavailable';
    public readonly ACTION_MATERIAL_INACTIVE_MARKET = 'material_inactive_market';
    public readonly ACTION_MATERIAL_REPLACED = 'material_replaced';
    public readonly ACTION_REMOVED = 'removed';

    public isMaterialDisabled(materialAction): boolean {
        return [
            this.ACTION_MATERIAL_INACTIVE,
            this.ACTION_MATERIAL_INACTIVE_MARKET,
            this.ACTION_MATERIAL_UNAVAILABLE,
            this.ACTION_REMOVED
        ].indexOf(materialAction) > -1;
    }

    public isMaterialInactive(materialAction): boolean {
        return [
            this.ACTION_MATERIAL_INACTIVE,
            this.ACTION_MATERIAL_INACTIVE_MARKET,
        ].indexOf(materialAction) > -1;
    }
}

import {Injectable} from '@angular/core';
import {ApiAdapter} from "../../shared/adapter/api.adapter";
import {SigPartner} from "../../shared/models/sig-partner.model";

@Injectable()
export class SigPartnerAdapter implements ApiAdapter<SigPartner> {
    public fromApi(sigPartner: any) {
        const out = {
            guid: sigPartner.guid,
            cid: sigPartner.cid,
            crm_partner: undefined,
        };

        if (sigPartner.crmPartner) {
            let email = sigPartner.crmPartner.email2;
            if (!email) {
                email = sigPartner.crmPartner.email1;
            }

            let phone = sigPartner.crmPartner.phone1;
            if (!phone) {
                phone = sigPartner.crmPartner.phone2;
            }

            out.crm_partner = {
                guid: sigPartner.crmPartner.guid,
                name: sigPartner.crmPartner.nameSearch,
                email: email,
                phone: phone,
                debit_code: sigPartner.crmPartner.debitCode,
                segmentation: null,
                parent: null,
                type: null,
            };

            if (sigPartner.crmPartner.crmPartnerSegmentation) {
                out.crm_partner.segmentation = sigPartner.crmPartner.crmPartnerSegmentation.name;
            }

            if (sigPartner.crmPartner.crmPartnerParent) {
                let email = sigPartner.crmPartner.crmPartnerParent.email2;
                if (!email) {
                    email = sigPartner.crmPartner.crmPartnerParent.email1;
                }

                let phone = sigPartner.crmPartner.crmPartnerParent.phone1;
                if (!phone) {
                    phone = sigPartner.crmPartner.crmPartnerParent.phone2;
                }

                /** TODO Fix this in a more proper way */
                out.crm_partner.parent = {
                    guid: sigPartner.crmPartner.crmPartnerParent.guid,
                    name: sigPartner.crmPartner.crmPartnerParent.nameSearch,
                    email: email,
                    phone: phone,
                    debit_code: sigPartner.crmPartner.crmPartnerParent.debitCode,
                    segmentation: null,
                };

                if (sigPartner.crmPartner.crmPartnerParent.crmPartnerSegmentation) {
                    out.crm_partner.parent.segmentation = sigPartner.crmPartner.crmPartnerParent.crmPartnerSegmentation.name;
                }

                if (sigPartner.crmPartner.crmPartnerParent.type) {
                    out.crm_partner.parent.type = {
                        guid: sigPartner.crmPartner.crmPartnerParent.type.guid,
                        cid: sigPartner.crmPartner.crmPartnerParent.type.cid,
                        name: sigPartner.crmPartner.crmPartnerParent.type.name,
                    };
                }
            }

            if (sigPartner.crmPartner.type) {
                out.crm_partner.type = {
                    guid: sigPartner.crmPartner.type.guid,
                    cid: sigPartner.crmPartner.type.cid,
                    name: sigPartner.crmPartner.type.name,
                };
            }
        }

        return out;
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-order-quantity-selector',
    templateUrl: './order-quantity-selector.component.html',
    styleUrls: ['./order-quantity-selector.component.scss']
})
export class OrderQuantitySelectorComponent implements OnInit {

    @Input() quantity?: number = null;
    @Input() minimumOrderQuantity?: number = null;
    @Input() quantityMultiplier?: number = null;
    @Input() showError: boolean = false;
    @Output() quantityChange: EventEmitter<number> = new EventEmitter<number>();

    public ngOnInit(): void {
        if (this.quantity === 0) {
            this.quantity = null;
        }

        if (!this.quantityMultiplier) {
            this.quantityMultiplier = 1;
        }
    }

    public quantityIncrease() {
        if (this.quantity) {
            if (typeof this.quantity !== 'number') {
                this.quantity = parseInt(this.quantity);
            }

            if (typeof this.quantityMultiplier !== 'number') {
                this.quantityMultiplier = parseInt(this.quantityMultiplier);
            }

            this.quantity += this.quantityMultiplier;
        } else {
            this.quantity = this.quantityMultiplier;
        }

        this.quantityChange.emit(this.quantity);
    }

    public quantityDecrease() {
        if (this.quantity) {
            if (typeof this.quantity !== 'number') {
                this.quantity = parseInt(this.quantity);
            }

            if (typeof this.quantityMultiplier !== 'number') {
                this.quantityMultiplier = parseInt(this.quantityMultiplier);
            }

            if (this.quantity - this.quantityMultiplier > 0) {
                this.quantity -= this.quantityMultiplier;
            }
        }

        this.quantityChange.emit(this.quantity);
    }
}

import { Action } from '@ngrx/store';
import { Error } from './error.model';

const KEY                     = 'ERROR';
export const ERROR_THROWN     = `[${KEY}] ErrorThrown`;

export class ThrowAction implements Action {
    public readonly type = ERROR_THROWN
    constructor(public payload: Error) {}
}

export type ErrorActions = ThrowAction


import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {SharedApiService} from "../../services/api.service";

@Component(
        {
            selector: 'shared-app-search-query-suggestions',
            templateUrl: './app-search-query-suggestions.component.html',
        }
)
export class AppSearchQuerySuggestionsComponent {
    @Input() public query: string = '';
    @Output() private submitQuery = new EventEmitter<string>();

    constructor(
            private apiService: SharedApiService,
    ) {
    }

    public onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.submit();
        }
    }

    public suggestions = (text$: Observable<string>) => {
        return text$.pipe(
                switchMap((term: string) => {
                    return this.apiService.getAppSearchQuerySuggestions(term.trim());
                }),
                map((response) => {
                    if (response.hasOwnProperty('results') && response.results && response.results.documents && response.results.documents.length > 0) {
                        return response.results.documents;
                    }

                    return [];
                })
        );
    }

    public selectSuggestion(event) {
        this.query = this.suggestionFormatter(event.item);

        this.submit();
    }

    public suggestionFormatter(item: any) {
        if (typeof item === 'object') {
            return item.suggestion;
        }

        return item;
    }

    public submit(): void {
        if (typeof this.query === 'object') {
            this.query = this.suggestionFormatter(this.query);
        }

        this.submitQuery.emit(this.query);
    }

    clearSearchQuery() {
        this.query = '';
    }
}

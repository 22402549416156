import {Component, Input, OnInit} from '@angular/core';
import {ArticlePriceService} from '../../services/article-price.service';
import {AuthService} from "../../services/auth.service";
import {ArticlePrice} from "../../models/article-price.model";
import {TableBodyRowCell} from "../../../view/models/table-body-row-cell.model";

@Component(
        {
            templateUrl: './article-price-per-ordering-unit-view-wrapper.component.html'
        }
)
export class ArticlePricePerOrderingUnitViewWrapperComponent implements OnInit {
    @Input() private values: {
        articleGuid: string,
        quantityCell: TableBodyRowCell
    };

    public price: ArticlePrice = null;

    constructor(
            public authService: AuthService,
            private articlePriceService: ArticlePriceService,
    ) {

    }

    /**
     * Initialize component
     */
    public ngOnInit() {
        this.articlePriceService.subscribeToArticle(this.values.articleGuid).subscribe(response => {
            this.price = response;
        });
    }

    get quantity(): number {
        return parseInt(this.values.quantityCell.value);
    }
}

import {Component, Inject} from "@angular/core";
import {EnvService} from "../shared/services/env.service";
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {DOCUMENT} from "@angular/common";

@Component(
        {
            selector: 'app-root',
            template: '<router-outlet></router-outlet>',
        }
)
export class AppComponent {
    public currentLang: string;

    constructor(
            public envService: EnvService,
            private titleService: Title,
            private translateService: TranslateService,
            @Inject(DOCUMENT) private document: Document
    ) {
    }

    public ngOnInit() {
        this.translateService.use(this.determineLocale(localStorage.getItem('locale') || this.envService.defaultLocale));
        this.setPageTitle(this.envService.pageTitle);

        this.setDocumentLang(this.translateService.currentLang);
        this.translateService.onLangChange.subscribe((result) => {
            this.setDocumentLang(result.lang);
        });
    }

    private setPageTitle(title: string) {
        this.titleService.setTitle(title);
    }

    private setDocumentLang(lang: string) {
        this.document.documentElement.lang = lang;
    }

    private determineLocale(locale: string): string {
        if (locale === 'nl-NL') {
            return 'nl';
        }

        return locale
    }
}

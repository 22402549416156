import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()

export class RoleGuardService implements CanActivate {
    /**
     * Constructor
     */
    constructor(private authService: AuthService, private router: Router) {
    }

    /**
     * Check if route can be activated based on role
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.hasRole(route.data.expectedRole)) {
            return true;
        } else {
            return this.router.parseUrl('/');
        }
    }
}

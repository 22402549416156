import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {Loader} from "../models/loader.model";

@Injectable()
export class LoaderService {
    private loaderSubject = new Subject<Loader>();

    loader = this.loaderSubject.asObservable();

    /**
     * Constructor
     */
    constructor() {
    }

    /**
     * Show loader
     */
    show() {
        // Set in timeout to prevent error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed...
        setTimeout(() => {
            this.loaderSubject.next(<Loader>{show: true});
        });
    }

    /**
     * Hide loader
     */
    hide() {
        // Set in timeout to prevent error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed...
        setTimeout(() => {
            this.loaderSubject.next(<Loader>{show: false});
        });
    }
}

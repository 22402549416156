import {Injectable} from "@angular/core";

@Injectable()
export class WarehouseService {
    public getSegmentationNames(warehouse: any) {
        let segments = [];

        let getSegmentationName = function (cid: string, name: string) {
            if ((cid === 'dry_finish' || cid === 'wet_finish')) {
                return 'Afbouw';
            } else {
                return name;
            }
        };

        if (warehouse['sigPartnerOrganisations']) {
            warehouse['sigPartnerOrganisations'].forEach(function (warehouseChild) {
                if (warehouseChild['partnerSegmentation']) {
                    let segmentationName = getSegmentationName(warehouseChild['partnerSegmentation']['cid'], warehouseChild['partnerSegmentation']['name']);
                    if (segments.indexOf(segmentationName)) {
                        segments.push(segmentationName);
                    }
                }
            });
        }

        segments.sort();

        return segments;
    }

    public getSegmentationCids(warehouse: any) {
        let segments = [];

        if (warehouse['sigPartnerOrganisations']) {
            warehouse['sigPartnerOrganisations'].forEach(function (warehouseChild) {
                if (warehouseChild['partnerSegmentation']) {
                    segments.push(warehouseChild['partnerSegmentation']['cid']);
                }
            });
        }

        return segments;
    }
}

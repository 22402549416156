import {Component, Input, OnInit} from "@angular/core";
import {SharedApiService} from "../../../../shared/services/api.service";
import {User} from "../../../../shared/models/user.model";

@Component({
    selector: 'switch-to-original-user',
    templateUrl: './switch-to-original-user.component.html',
})
export class SwitchToOriginalUserComponent implements OnInit {
    private backendBaseUrl: string = '';

    @Input()
    public originalUser?: User;

    constructor(
        private apiService: SharedApiService,
    ) {
    }

    public ngOnInit() {
        this.apiService.getBackendBaseUrl().subscribe(backendBaseUrl => this.backendBaseUrl = backendBaseUrl);
    }

    public switchToOriginalUser() {
        this.apiService.switchToOriginalUser().subscribe(() => {
            location.href = this.backendBaseUrl;
        }, () => {
            location.href = this.backendBaseUrl;
        });
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {TableBodyRowCell} from "../../../view/models/table-body-row-cell.model";
import {FormatterService} from "../../services/formatter.service";

@Component({
    templateUrl: './view-order-delivery.component.html',
})
export class ViewOrderDeliveryComponent implements OnInit {
    @Input() public values: {
        orderDeliveryDateCell: TableBodyRowCell,
        orderDeliveryTimeCell: TableBodyRowCell,
    };

    constructor(
        private formatterService: FormatterService,
    ) {
    }

    public ngOnInit() {
        if (this.values.orderDeliveryDateCell.value) {
            this.values.orderDeliveryDateCell.value = this.formatterService.formatDate(this.values.orderDeliveryDateCell.value);
        }
    }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {ContainerComponent} from "./container.component";
import {AlertsResolver} from "./resolvers/alerts.resolver";

const routes: Routes = [
    {
        path: 'linktree',
        loadChildren: () => import('../linktree/linktree.module').then(module => module.LinktreeModule),
    },
    {
        path: '',
        component: ContainerComponent,
        resolve: {
            alertsResult: AlertsResolver
        },
        children: [
            {
                path: '',
                loadChildren: () => import('../home/home.module').then(module => module.HomeModule),
            },
            {
                path: '',
                loadChildren: () => import('../shared/shared.module').then(module => module.SharedModule),
            },
            {
                path: 'assortment',
                loadChildren: () => import('../assortment/assortment.module').then(module => module.AssortmentModule),
            },
            {
                path: 'catalogue',
                loadChildren: () => import('../catalogue/catalogue.module').then(module => module.CatalogueModule),
            },
            {
                path: 'components',
                loadChildren: () => import('../components/components.module').then(module => module.ComponentsModule),
            },
            {
                path: 'contact',
                loadChildren: () => import('../contact/contact.module').then(module => module.ContactModule),
            },
            {
                path: 'content',
                loadChildren: () => import('../content/content.module').then(module => module.ContentModule),
            },
            {
                path: 'news',
                loadChildren: () => import('../news/news.module').then(module => module.NewsModule),
            },
            {
                path: 'order',
                loadChildren: () => import('../order/order.module').then(module => module.OrderModule),
            },
            {
                path: 'shopping-cart',
                loadChildren: () => import('../shopping-cart/shopping-cart.module').then(module => module.ShoppingCartModule),
            },
            {
                path: 'start',
                loadChildren: () => import('../start/start.module').then(module => module.StartModule),
            },
            {
                path: 'user',
                loadChildren: () => import('../user/user.module').then(module => module.UserModule),
            },
            {path: '404', component: PageNotFoundComponent},
            {path: '**', component: PageNotFoundComponent}
        ]
    }
];

@NgModule(
        {
            declarations: [],
            imports: [
                CommonModule,
                RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})
            ],
            exports: [RouterModule]
        }
)

export class AppRoutingModule {
}

import {Component} from "@angular/core";
import {Observable} from "rxjs";
import {debounceTime, map, switchMap} from "rxjs/operators";
import {SharedApiService} from "../../../../shared/services/api.service";
import {HttpParams} from "@angular/common/http";
import {SearchResult} from "../../../../catalogue/models/searchresult.model";
import {Router} from "@angular/router";
import {slugify} from "../../../../shared/util/slugify";
import {EnvService} from "../../../../shared/services/env.service";

@Component({
    selector: 'app-type-ahead-search',
    templateUrl: './type-ahead-search.component.html',
    styleUrls: ['./type-ahead-search.component.scss']
})
export class TypeAheadSearchComponent {
    public model: any;
    private searchType: string;

    constructor(
        private apiService: SharedApiService,
        private router: Router,
        private envService: EnvService
    ) {
    }

    searchProducts = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(1000),
            switchMap((term: string) => {
                term = term.trim();

                if (term.length < 3) {
                    return [];
                }

                let params = new HttpParams({fromObject: {q: term}});

                return this.apiService.search(params);
            }),
            map((response: SearchResult<any>) => {
                this.searchType = response.searchType;

                if (response.hasOwnProperty('items') && response.items && response.items.length > 0) {
                    return response.items.slice(0, 10);
                }

                return [];
            })
        );
    }

    formatter(value: any) {
        return value.name;
    }

    public search() {
        this.model = this.model.trim();

        let queryParams = {
            q: this.model,
        };

        if (['MPA', 'Isolatec'].includes(this.envService.companyName)) {
            queryParams['force_switch'] = 1;
            queryParams['search_type'] = 'articles';
        }

        this.router.navigate(['/catalogue'], {
            queryParams: queryParams
        }).then();
    }

    public selectProduct(event) {
        if (event.hasOwnProperty('item') && event.item.hasOwnProperty('guid')) {
            if (this.searchType === 'products') {
                this.router.navigate(['/catalogue/product', event.item.guid, slugify(event.item.name)]);
            } else {
                this.router.navigate(['/catalogue/article', event.item.guid, slugify(event.item.name)]);
            }
        }
    }
}

import {Injectable} from '@angular/core';
import {ShoppingCartItem} from "../models/shopping-cart-item.model";
import {ArticleAdapter} from "../../catalogue/adapter/article.adapter";
import {ApiAdapter} from "../../shared/adapter/api.adapter";

@Injectable()
export class ShoppingCartItemAdapter implements ApiAdapter<ShoppingCartItem> {

    constructor(
        private articleAdapter: ArticleAdapter
    ) {
    }

    public fromApi(shoppingCartItem: any) {
        return {
            guid: shoppingCartItem.guid,
            article: this.articleAdapter.fromApi(shoppingCartItem.article),
            quantity: shoppingCartItem.quantity,
            oldQuantity: shoppingCartItem.quantity
        };
    }
}

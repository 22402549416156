import {Component, Input, OnInit} from '@angular/core';
import {ArticlePriceService} from '../../services/article-price.service';
import {AuthService} from "../../services/auth.service";
import {ArticlePrice} from "../../models/article-price.model";

@Component(
        {
            selector: 'app-shared-article-price-total-wrapper',
            templateUrl: './article-price-total-wrapper.component.html'
        }
)
export class ArticlePriceTotalWrapperComponent implements OnInit {
    @Input() private values: {
        articleGuid: string,
        quantity: number
    };

    public price: ArticlePrice = null;

    constructor(
            public authService: AuthService,
            private articlePriceService: ArticlePriceService,
    ) {

    }

    /**
     * Initialize component
     */
    public ngOnInit() {
        this.articlePriceService.subscribeToArticle(this.values.articleGuid).subscribe(response => {
            this.price = response;
        });
    }

    get quantity(): number {
        return this.values.quantity;
    }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {SharedApiService} from '../services/api.service';

@Injectable()
export class WarehousesResolver implements Resolve<any> {
    constructor(private apiService: SharedApiService) {
    }

    public resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.apiService.getParentOrganisations();
    }
}

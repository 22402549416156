import {Component, Input} from "@angular/core";
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {Carousel} from "../../models/carousel.model ";

@Component({
    selector: 'app-brand-carousel',
    templateUrl: './brand-carousel.component.html'
})
export class BrandCarouselComponent {

    @Input() public carousel: Carousel;

    public carouselConfig: SwiperConfigInterface = {
        speed: 1000,
        direction: 'horizontal',
        slidesPerView: 5,
        spaceBetween: 32,
        autoplay: {
            delay: 1500,
            disableOnInteraction: true
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            960: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
            1400: {
                slidesPerView: 5,
            }
        }
    };
}

import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  /**
   * Constructor
   */
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * Check if route can be activated if user is logged in
   */
  public canActivate(route: ActivatedRouteSnapshot) {
    if (this.authService.isAuthenticated()) {
        return true;
    } else {
        return this.router.parseUrl('/');
    }
  }
}

import {HttpClientModule} from "@angular/common/http";
import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerModule} from "ngx-spinner";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {FooterComponent} from "./components/footer/footer.component";
import {HeaderComponent} from "./components/header/header.component";
import {localStorageSyncReducer, SharedModule} from "../shared/shared.module";
import {importTranslateModule} from "../shared/translate/import";
import {ViewModule} from "../view/view.module";
import {ToastrModule} from "ngx-toastr";
import {GoTopButtonModule} from "ng-go-top-button";
import {registerLocaleData} from "@angular/common";
import localeNl from "@angular/common/locales/nl";
import {TranslateService} from "@ngx-translate/core";
import {EffectsModule} from "@ngrx/effects";
import {MetaReducer, StoreModule} from "@ngrx/store";
import {AuthenticationReducer} from "../shared/store/auth/auth.reducer";
import {ErrorReducer} from "../shared/store/error/error.reducer";
import {AuthEffects} from "../shared/store/auth/auth.effects";
import {ClampyModule} from "@clampy-js/ngx-clampy";
import {ErrorEffects} from "../shared/store/error/error.effects";
import {TranslateInitializerFactory} from "../shared/translate/initializer.factory";
import {MenuService} from "../shared/services/menu.service";
import {AppInitService} from "./services/app-init.service";
import {AuthService} from "../shared/services/auth.service";
import {CatalogComponent} from "./components/header/catalog/catalog.component";
import {CookieBarComponent} from './components/cookie-bar/cookie-bar.component';
import {GoogleMapsModule} from "@angular/google-maps";
import {QuestionModalComponent} from "./components/footer/components/question-modal/question-modal.component";
import {RecaptchaFormsModule, RecaptchaModule, RECAPTCHA_LANGUAGE} from "ng-recaptcha";
import {MobileMenuCardComponent} from './components/mobile-menu-card/mobile-menu-card.component';
import {ContainerComponent} from "./container.component";
import {TypeAheadSearchComponent} from "./components/header/type-ahead-search-component/type-ahead-search.component";
import {
    SwitchToOriginalUserComponent
} from "./components/header/switch-to-original-user/switch-to-original-user.component";
import {AlertAdapter} from "./components/adapter/alert.adapter";
import {AlertsResolver} from "./resolvers/alerts.resolver";

// Register NL locale data
registerLocaleData(localeNl);

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

export function initializeApp(appInitService: AppInitService) {
    return (): Promise<any> => {
        return appInitService.Init();
    };
}

@NgModule({
    declarations: [
        AppComponent,
        ContainerComponent,
        HeaderComponent,
        FooterComponent,
        CatalogComponent,
        CookieBarComponent,
        QuestionModalComponent,
        MobileMenuCardComponent,
        TypeAheadSearchComponent,
        SwitchToOriginalUserComponent,
    ],
    providers: [
        AppInitService,
        {
            provide: APP_INITIALIZER,
            useFactory: TranslateInitializerFactory,
            deps: [TranslateService, AuthService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitService],
            multi: true,
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'EUR'
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: localStorage.getItem('locale') ? localStorage.getItem('locale') : window['__env'].defaultLocale
        },
        MenuService,
        AlertAdapter,
        AlertsResolver
    ],
    imports: [
        AppRoutingModule,
        importTranslateModule(),
        SharedModule.forRoot(),
        ViewModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
        }),
        GoTopButtonModule,
        // TODO(rharink): these are imported through SharedModule, move them?
        StoreModule.forRoot(
                {
                    auth: AuthenticationReducer,
                    error: ErrorReducer,
                },
                {
                    metaReducers,
                }
        ),
        // TODO(rharink): these are imported through SharedModule, move them?
        EffectsModule.forRoot([AuthEffects, ErrorEffects]),
        ClampyModule,
        GoogleMapsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
    ],
    exports: [],
    entryComponents: [
        QuestionModalComponent
    ],
    bootstrap: [
        AppComponent
    ],
})
export class AppModule {
}

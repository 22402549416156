import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {SharedApiService} from "./api.service";

@Injectable()
export class SigPartnerResolver implements Resolve<any> {
    constructor(private apiService: SharedApiService) {
    }

    public resolve(): Observable<any> {
        return this.apiService.getCurrentSigPartner();
    }
}

import {Component, Input} from '@angular/core';
import {SwiperConfigInterface} from "ngx-swiper-wrapper";

@Component(
        {
            selector: 'app-background-image-carousel',
            templateUrl: './background-image-carousel.component.html',
            styleUrls: [
                './background-image-carousel.component.scss'
            ]
        }
)
export class BackgroundImageCarouselComponent {
    @Input() public items;

    public carouselConfig: SwiperConfigInterface = {
        speed: 1500,
        direction: 'horizontal',
        slidesPerView: 1,
        loop: true,
        loopAdditionalSlides: 2,
        autoplay: {
            delay: 3500,
            disableOnInteraction: true
        },
    };
}
import {Component, Input} from '@angular/core';
import {TableBodyRowCell} from "../../../view/models/table-body-row-cell.model";

@Component({
    templateUrl: './article-update-quantity.component.html',
})
export class ArticleUpdateQuantityComponent {
    @Input() public values: {
        quantityCell: TableBodyRowCell,
        minimumOrderQuantityCell: TableBodyRowCell,
        quantityMultiplierCell: TableBodyRowCell
    };

    constructor() {
    }

    get quantity() {
        let quantity = parseInt(this.values.quantityCell.value);

        if (isNaN(quantity)) {
            return 0;
        }

        return quantity;
    }

    set quantity(value) {
        if (!value) {
            this.values.quantityCell.value = '';
        } else {
            this.values.quantityCell.value = value.toString();
        }
    }

    get minimumOrderQuantity() {
        let minimumOrderQuantity = parseInt(this.values.minimumOrderQuantityCell.value);

        if (isNaN(minimumOrderQuantity)) {
            return 1;
        }

        // console.log(minimumOrderQuantity);
        return minimumOrderQuantity;
    }

    get quantityMultiplier() {
        let quantityMultiplier = parseInt(this.values.quantityMultiplierCell.value);

        if (isNaN(quantityMultiplier)) {
            return 1;
        }

        // console.log(quantityMultiplier);
        return quantityMultiplier;
    }
}

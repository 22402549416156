import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { ApiAwareHttpClient } from '../services/api_aware_http_client';

export class SyntecMissingTranslationHandler implements MissingTranslationHandler {
    public handle(params: MissingTranslationHandlerParams) {
        console.warn(`Missing translation for "sig.frontend.${params.key}"`);

        return params.key;
    }
}

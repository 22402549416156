import {Injectable} from '@angular/core';
import {ApiAdapter} from "./api.adapter";
import {Project} from "../models/project.model";

@Injectable()
export class ProjectAdapter implements ApiAdapter<Project> {
    public fromApi(project: any) {
        return {
            guid: project.guid,
            cid: project.cid,
            name: project.name,
        };
    }
}

import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiAwareHttpClient } from '../services/api_aware_http_client';

export class SyntecTranslateLoader implements TranslateLoader {

    constructor(private apiClient: ApiAwareHttpClient) {
    }

    public getTranslation(lang: string): Observable<any> {
        return this.apiClient.get(`/core/translation/${lang}.json?scope=sig-frontend`)
            .pipe(map((ev: object) => {
                const shortTranslations = {};

                for (const key in ev) {
                    if (ev.hasOwnProperty(key)) {
                        const value = ev[key];
                        const shortKey = key.replace('sig.frontend.', '');
                        shortTranslations[shortKey] = value;
                    }
                }

                return shortTranslations;
            }));
    }
}

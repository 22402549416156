import {Inject, Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({name : 'customDate'})
export class CustomDatePipe extends DatePipe implements PipeTransform {
    constructor(@Inject('DATE_FORMAT') public dateFormat: string) {
        super('');
    }

    public transform(value: any, args?: any): any {
        return moment(value).format(this.dateFormat);
    }
}

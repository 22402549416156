export enum CoreErrors {
    EMPTY                               = 'CORE-0',
    NOT_FOUND                           = 'CORE-10000',
    AUTH_UNAUTHORIZED                   = 'CORE-20000',
    AUTH_USER_NOT_FOUND                 = 'CORE-20001',
    AUTH_USER_INACTIVE                  = 'CORE-20002',
    AUTH_INVALID_PASSWORD               = 'CORE-20003',
    AUTH_INVALID_TOKEN                  = 'CORE-20010',
    AUTH_EXPIRED_TOKEN                  = 'CORE-20011',
    AUTH_INVALID_CLAIMS                 = 'CORE-20012',
    AUTH_INCORRECT_PASSWORD             = 'CORE-20013',
    AUTH_FORBIDDEN                      = 'CORE-30000',
    DB_INTEGRITY_CHECK_FAILED           = 'CORE-80000',
    DB_INTEGRITY_CHECK_FILE_NOT_FOUND   = 'CORE-80001',
    DB_DUPLICATE_KEY                    = 'CORE-80002',
    UNKNOWN                             = 'CORE-99999',
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-shared-article-image',
  templateUrl: './article-image.component.html',
  styleUrls: ['./article-image.component.scss']
})
export class ArticleImageComponent implements OnInit {

  @Input() public values: {articleGuid: string, imageUrl: string};

  public imageUrl: string;

  constructor() { }

  /**
   * Initialize component
   */
  public ngOnInit() {
    this.imageUrl = this.values.imageUrl || 'assets/images/placeholder.jpg';
  }
}

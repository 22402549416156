/** Outer dependencies */
import { Injectable, Inject } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';

@Injectable()
export class CustomDateAdapter extends NgbDateAdapter<string> {
  private hour = 0;
  private minute = 0;
  private second = 0;

  constructor(@Inject('DATE_FORMAT') private dateFormat: string) {
    super();
  }

  /**
   * Converts user-model date into an NgbDateStruct for internal use in the library
   */
  public fromModel(dateString: string): NgbDateStruct {
    const date = moment(dateString, [this.dateFormat, moment.ISO_8601], true);
    if (!date.isValid()) {
      return null;
    }

    this.hour = date.hours();
    this.minute = date.minutes();
    this.second = date.seconds();

    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date()
    };
  }

  /**
   * Converts internal date value NgbDateStruct to user-model date
   * The returned type is supposed to be of the same type as fromModel() input-value param
   */
  public toModel(date: NgbDateStruct): string {
    if (date) {
      return moment()
          .year(date.year)
          .month(date.month - 1)
          .date(date.day)
          .hour(this.hour)
          .minute(this.minute)
          .second(this.second)
          .format();
    }

    return null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  constructor(@Inject('DATE_FORMAT') private dateFormat: string) {
    super();
  }

  public parse(dateString: string): NgbDateStruct | null {
    if (dateString) {
      const date = moment(dateString, [this.dateFormat, moment.ISO_8601], true);
      if (!date.isValid()) {
        return null;
      }

      return {
        year: date.year(),
        month: date.month() + 1,
        day: date.date()
      };
    }
    return null;
  }

  public format(date: NgbDateStruct | null): string | null {
    if (date) {
      return moment()
          .year(date.year)
          .month(date.month - 1)
          .date(date.day)
          .format(this.dateFormat);
    } else {
      return null;
    }

  }
}

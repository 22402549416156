import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiAwareHttpClient, RequestOptions} from './api_aware_http_client';
import {catchError, map} from 'rxjs/operators';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {ViewParams} from '../../view/models/view-params.model';
import {View} from '../../view/models/view.model';
import {AlertService} from './alert.service';
import {TranslateService} from '@ngx-translate/core';
import {Record} from '../models/record.model';
import {RecordResponse} from '../models/record-response.model';
import {FileService} from "./file.service";
import {File} from "../models/file.model";
import {SearchResult} from "../../catalogue/models/searchresult.model";
import {ProductAdapter} from "../../catalogue/adapter/product.adapter";
import {AddToShoppingCarts} from "../models/add-to-shopping-cart.model";
import {Product} from "../../catalogue/models/product.model ";
import {ArticleAdapter} from "../../catalogue/adapter/article.adapter";
import {Article, Articles} from "../../catalogue/models/article.model";
import {User} from "../models/user.model";
import {SigPartner} from "../models/sig-partner.model";
import {ShoppingCartItemAdapter} from "../../shopping-cart/adapter/shoppingCartItem.adapter";
import {ShoppingCart} from "../../shopping-cart/models/shopping-cart.model";
import {ShoppingCartCount} from "../models/shopping-cart-count";
import {ShoppingCartCountAdapter} from "../../shopping-cart/adapter/shoppingCartCount.adapter";
import {GenderAdapter} from "../adapter/gender.adapter";
import {Genders} from "../models/gender.model";
import {OrderAdapter} from "../../order/adapter/order.adapter";
import {Order} from "../../order/models/order.model";
import {CarouselAdapter} from "../adapter/carousel.adapter";
import {Carousel, Carousels} from "../models/carousel.model ";
import {ShoppingCartItems} from "../../shopping-cart/models/shopping-cart-item.model";
import {Newsletter} from "../../content/components/models/newsletter.model";
import {SalesContact} from "../../content/components/models/sales-contact.model";
import {LoginRequest} from 'src/modules/user/models/login-request.model';
import {NewsItem, NewsItems} from "../../news/models/news-item.model ";
import {NewsCategories, NewsCategory} from "../../news/models/news-category.model ";
import {NewsItemAdapter} from "../../news/adapter/newsItem.adapter";
import {NewsCategoryAdapter} from "../../news/adapter/newsCategory.adapter";
import {ArticlePrices} from '../models/article-price.model';
import {Router} from "@angular/router";
import {slugify} from "../util/slugify";
import {NewsLetters} from "../../content/models/newsletter.model";
import {LinkBlocks} from "../../linktree/models/link-block.model";
import {Job, Jobs} from "../../content/components/jobs/models/job.model";
import {AlertAdapter} from "../../app/components/adapter/alert.adapter";
import {Alerts} from "../../app/models/alert.model";
import {AppSearchAdapter} from "../../catalogue/adapter/app-search.adapter";
import {AppSearch} from "../../catalogue/models/appSearch.model";

@Injectable()
export class SharedApiService {

    constructor(
            public apiClient: ApiAwareHttpClient,
            public productAdapter: ProductAdapter,
            public articleAdapter: ArticleAdapter,
            public shoppingCartItemAdapter: ShoppingCartItemAdapter,
            public shoppingCartCountAdapter: ShoppingCartCountAdapter,
            public genderAdapter: GenderAdapter,
            public fileService: FileService,
            public alertService: AlertService,
            private translateService: TranslateService,
            private orderAdapter: OrderAdapter,
            private carouselAdapter: CarouselAdapter,
            private newsItemAdapter: NewsItemAdapter,
            private newsCategoryAdapter: NewsCategoryAdapter,
            private router: Router,
            private alertAdapter: AlertAdapter,
            private appSearchAdapter: AppSearchAdapter
    ) {
    }

    public getCurrentUser(): Observable<User> {
        return this.apiClient.get(`/core/user`);
    }

    public updateCurrentUser(user: User): Observable<User> {
        return this.apiClient.put(`/core/user`, user);
    }

    public updatePassword(currentPassword: string, newPassword: string): Observable<unknown> {
        return this.apiClient.post(
                `/core/user/updatepassword`,
                {
                    curPassword: currentPassword,
                    newPassword: newPassword
                },
                {
                    dispatchError: false,
                }
        ).pipe(catchError(err => this.catchPasswordError(err)));
    }

    public getCurrentSigPartner(): Observable<any> {
        return this.apiClient.get(`/sig/me`);
    }

    public markTermsAndConditionsAccepted(): Observable<SigPartner> {
        return this.apiClient.put(`/sig/me/mark_terms_and_conditions_accepted`, {});
    }

    public getCurrentSigPartnerColleagues(): Observable<any> {
        return this.apiClient.get(`/sig/me/colleagues`);
    }

    public getDeliveryTimeGroups(): Observable<any> {
        return this.apiClient.get(`/sig/delivery-time-groups`);
    }

    public getGenders(): Observable<Genders> {
        return this.apiClient.get(`/sig/user/genders`).pipe(map((genders: Genders) => genders.map((gender: any) => this.genderAdapter.fromApi(gender))));
    }

    public getCaptchaConfiguration(): Observable<Map<string, string>> {
        return this.apiClient.get(`/sig/configuration/captcha`);
    }

    public getGoogleAnalyticsConfiguration(): Observable<Map<string, string>> {
        return this.apiClient.get(`/sig/configuration/google-tag-manager`);
    }

    public postOrder(shoppingCart: ShoppingCart): Observable<any> {
        return this.apiClient.post(`/sig/order`, shoppingCart);
    }

    public getAssortmentArticles(): Observable<Articles> {
        return this.apiClient.get(`/sig/assortment/assortment-articles`);
    }

    public getAssortmentFilters(): Observable<any> {
        return this.apiClient.get(`/sig/me/assortment/filters`);
    }

    public getArticlePricesByGuids(guids: Array<string>, sigPartnerOrganisationGuid?: string): Observable<ArticlePrices> {
        return this
                .apiClient
                .post(
                        `/sig/article/get-prices`,
                        {
                            sigArticleGuids: guids,
                            sigPartnerOrganisationGuid: sigPartnerOrganisationGuid
                        }
                )
                .pipe(map((prices: any[]) => prices.map(price => ({
                    ...price,
                }))));
    }

    public getArticleStockForMultipleLocationsByGuids(guids: Array<string>): Observable<any> {
        return this
                .apiClient
                .post(
                        `/sig/article/get-stock-for-multiple-locations`,
                        {
                            sigArticleGuids: guids,
                        }
                );
    }

    public getOrderCalculation(shoppingCart: ShoppingCart): Observable<any> {
        return this.apiClient.post(`/sig/shopping-cart/order-calculation`, shoppingCart);
    }

    public getShoppingCartItems(): Observable<ShoppingCartItems> {
        return this.apiClient.get(`/sig/shopping-cart`).pipe(map((shoppingCartItems: any) => shoppingCartItems.map((shoppingCartItem: any) => this.shoppingCartItemAdapter.fromApi(shoppingCartItem))));
    }

    public getShoppingCartCount(): Observable<ShoppingCartCount> {
        return this.apiClient.get(`/sig/shopping-cart/count`).pipe(map((shoppingCartCount: any) => this.shoppingCartCountAdapter.fromApi(shoppingCartCount)));
    }

    public addToShoppingCart(articles: AddToShoppingCarts): Observable<any> {
        return this
                .apiClient
                .post(
                        `/sig/shopping-cart/add`,
                        {
                            articles
                        }
                );
    }

    public addToAssortment(guids: Array<string>): Observable<any> {
        return this
                .apiClient
                .post(
                        `/sig/assortment/add`,
                        {
                            sigArticleGuids: guids,
                        }
                );
    }

    public updateShoppingCartQuantity(guid: string, quantity: number, options?: RequestOptions): Observable<any> {
        return this.apiClient.post(
                `/sig/shopping-cart/update-quantity`,
                {
                    sigShoppingCartGuid: guid,
                    quantity: quantity,
                },
                options
        );
    }

    public removeFromShoppingCart(guid: string): Observable<any> {
        return this.apiClient.delete(`/sig/shopping-cart/remove/${guid}`);
    }

    public emptyShoppingCart(): Observable<any> {
        return this.apiClient.delete(`/sig/shopping-cart/empty`);
    }

    public removeFromAssortment(guid: string): Observable<any> {
        return this.apiClient.delete(`/sig/assortment/remove/${guid}`);
    }

    private catchPasswordError(err) {
        if (err && err.error && err.error.error) {

            const title = this.translateService.instant('me.password.requirements')

            const errors = err.error.error.message
                    .split(',\n')
                    .map((e) => `<li>${e.trim()}</li>`)
                    .join('\n');

            this.alertService.alert(`
                <ul>
                    ${errors}
                </ul>
            `,
                    {
                        title,
                        size: 'lg'
                    });

        }
        return err;
    }

    /**
     * Search for products
     * @param params
     */
    public search(params: HttpParams): Observable<SearchResult<Product>> {
        return this
                .apiClient
                .get(`/sig/search`, {params: params})
                .pipe(
                        map((result: any) => {
                            if (typeof result === 'object') {
                                if (result.redirectUrl && typeof result.redirectUrl === 'string') {
                                    return this.router.navigateByUrl(result.redirectUrl);
                                } else if (result.guid && typeof result.guid === 'string') {
                                    const article = result as Article;

                                    return this.router.navigate(
                                            [
                                                '/catalogue/article',
                                                article.guid,
                                                slugify(article.name)
                                            ]
                                    );
                                }
                            }

                            result = result as SearchResult<Product>;

                            return result;
                        })
                );
    }

    public getProduct(id: string): Observable<Product> {
        return this.apiClient.get(`/sig/product/${id}`).pipe(map((x: any) => this.productAdapter.fromApi(x)));
    }

    public getArticle(id: string, options?: RequestOptions): Observable<Article> {
        return this.apiClient.get(`/sig/article/${id}`, options).pipe(map((article: any) => this.articleAdapter.fromApi(article)));
    }

    public getOrder(guid: string): Observable<Order> {
        return this.apiClient.get(`/sig/order/${guid}`)
                .pipe(map((order: any) => this.orderAdapter.fromApi(order)));
    }

    public checkresetpasswordtoken(token: string): Observable<boolean> {
        return this.apiClient.get(`/core/user/checkresetpasswordtoken/${token}`)
                .pipe(map((y: any) => y.success));
    }

    public resetPassword(token: string, password: string): Observable<any> {
        return this.apiClient.post(
                `/core/user/resetpassword`,
                {
                    token,
                    password
                },
                {
                    dispatchError: false
                }
        ).pipe(catchError(err => this.catchPasswordError(err)));
    }

    public loginRequest(loginRequest: LoginRequest): Observable<any> {
        return this.apiClient.post(`/sig/user/login-request`, loginRequest);
    }

    public sendSalesContact(salesContact: SalesContact): Observable<any> {
        return this.apiClient.post(`/sig/contact/sales-contact`, salesContact);
    }

    public newsletter(newsletter: Newsletter): Observable<any> {
        return this.apiClient.post(`/sig/contact/newsletter`, newsletter);
    }

    public newsletterConfirm(newsletter: Newsletter): Observable<any> {
        return this.apiClient.post(`/sig/contact/newsletter/confirm`, newsletter);
    }

    public forgotPassword(form): Observable<any> {
        return this.apiClient.post(`/sig/user/forgotpassword`, form);
    }

    public activateAccount(form): Observable<any> {
        return this.apiClient.post(`/sig/user/activate`, form);
    }

    public getProductPdf(guid: string): Observable<any> {
        return this.apiClient.get(
                `/sig/product/${guid}/pdf`,
                {
                    observe: 'response' as 'body',
                    responseType: 'blob' as 'json'
                }
        ).pipe(map((res: HttpResponse<any>) => {
            return this.fileService.getFile(res);
        }));
    }

    public getView(viewGuid: string, params: ViewParams): Observable<View> {
        return this.apiClient.post(`/core/view/${viewGuid}`, params);
    }

    public getViewXlsx(viewGuid: string, params: ViewParams): Observable<File> {
        return this.apiClient.post(`/core/view/${viewGuid}/xlsx`, params, {
            observe: 'response' as 'body',
            responseType: 'blob' as 'json'
        })
                .pipe(map((res: HttpResponse<any>) => {
                    return this.fileService.getFile(res);
                }));
    }

    public getCarousel(cid: string): Observable<Carousel> {
        return this.apiClient.get(`/sig/carousel/${cid}`).pipe(map((carousel: any) => this.carouselAdapter.fromApi(carousel)));
    }

    public getCarousels(cids: string[]): Observable<Carousels> {
        return this.apiClient.get(`/sig/carousels/` + JSON.stringify(cids)).pipe(map((carousels: any) => carousels.map((carousel: any) => this.carouselAdapter.fromApi(carousel))));
    }

    public getNewsItems(): Observable<NewsItems> {
        return this.apiClient.get(`/sig/news`).pipe(map((newsItems: any) => newsItems.map((newsItem: any) => this.newsItemAdapter.fromApi(newsItem))));
    }

    public getNewsItem(guid: string): Observable<NewsItem> {
        return this.apiClient.get(`/sig/news/${guid}`).pipe(map((newsItems: any) => this.newsItemAdapter.fromApi(newsItems)));
    }

    public getNewsCategories(): Observable<NewsCategories> {
        return this.apiClient.get(`/sig/news/categories`).pipe(map((newsCategories: any) => newsCategories.map((newsCategory: any) => this.newsCategoryAdapter.fromApi(newsCategory))));
    }

    public getNewsCategory(guid: string): Observable<NewsCategory> {
        return this.apiClient.get(`/sig/news/category/${guid}`).pipe(map((newsCategory: any) => this.newsCategoryAdapter.fromApi(newsCategory)));
    }

    public getParentOrganisations(): Observable<any> {
        return this.apiClient.get(`/sig/parent-organisations`);
    }

    public getOrganisation(guid: string): Observable<any> {
        return this.apiClient.get(`/sig/organisation/${guid}`);
    }

    public getManufacturers(): Observable<any> {
        return this.apiClient.get(`/sig/manufacturers`);
    }

    public getManufacturer(cid: string): Observable<any> {
        return this.apiClient.get(`/sig/manufacturer/${cid}`);
    }

    public getPriceUpdateSegments(): Observable<any> {
        return this.apiClient.get(`/sig/price-update-segments`);
    }

    public getPriceUpdateSegment(guid: string): Observable<any> {
        return this.apiClient.get(`/sig/price-update-segment/${guid}`);
    }

    public downloadPriceUpdatesReport(guid: string): Observable<any> {
        return this.apiClient.get(
                `/sig/price-update-segment/${guid}/download`,
                {
                    observe: 'response' as 'body',
                    responseType: 'blob' as 'json'
                }
        ).pipe(
                map(
                        (response: HttpResponse<any>) => {
                            return this.fileService.getFile(response);
                        }
                )
        );
    }

    public downloadPriceUpdatesYearlyReport(guid: string, year: number): Observable<any> {
        return this.apiClient.get(
                `/sig/price-update-segment/${guid}/download/${year}`,
                {
                    observe: 'response' as 'body',
                    responseType: 'blob' as 'json'
                }
        ).pipe(
                map(
                        (response: HttpResponse<any>) => {
                            return this.fileService.getFile(response);
                        }
                )
        );
    }

    public downloadPriceUpdatesMonthlyReport(guid: string, year: number, month: number): Observable<any> {
        return this.apiClient.get(
                `/sig/price-update-segment/${guid}/download/${year}/${month}`,
                {
                    observe: 'response' as 'body',
                    responseType: 'blob' as 'json'
                }
        ).pipe(
                map(
                        (response: HttpResponse<any>) => {
                            return this.fileService.getFile(response);
                        }
                )
        );
    }

    public getPriceUpdateSegmentPerMonth(guid: string): Observable<any> {
        return this.apiClient.get(`/sig/price-update-segment-per-month/${guid}`);
    }

    public getAddressInformation(zipcode, number, extension): Observable<any> {
        return this.apiClient.post(
                `/sig/address/information`,
                {
                    zipcode: zipcode,
                    number: number,
                    extension: extension,
                    country: 'nl',
                }
        );
    }

    public requestAssortmentPriceList(): Observable<any> {
        return this.apiClient.post(`/sig/assortment/request-price-list`, {});
    }

    public getRecord(formGuid: string, guid?: string | null): Observable<Record> {
        if (guid) {
            return this.apiClient.get(`/core/record/${formGuid}/${guid}`);
        } else {
            return this.apiClient.get(`/core/record/${formGuid}`);
        }
    }

    public saveRecord(formGuid: string, data: any, guid?: string): Observable<RecordResponse> {
        if (guid) {
            return this.apiClient.put(`/core/record/${formGuid}/${guid}`, data);
        } else {
            // When the data is an array, convert it to an object
            if (Array.isArray(data)) {
                data = Object.assign({}, data);
            }

            return this.apiClient.post(`/core/record/${formGuid}`, data);
        }
    }

    public getOrganisations(): Observable<any> {
        return this.apiClient.get(`/sig/me/organisations`);
    }

    public setSigPartnerOrganisation(sigPartnerOrganisationGuid: string): Observable<any> {
        return this.apiClient.put(`/sig/me/organisation/${sigPartnerOrganisationGuid}`, {});
    }

    public getHasPriceUpdates(): Observable<any> {
        return this.apiClient.get(`/sig/price-updates/person-has-price-updates`, {});
    }

    public acknowledgePriceUpdates(): Observable<any> {
        return this.apiClient.post(`/sig/price-updates/person-acknowledge-price-updates`, {})
    }

    public getAlerts(): Observable<Alerts> {
        return this.apiClient.get(`/sig/alerts`).pipe(map((alerts: any) => alerts.map((alert: any) => this.alertAdapter.fromApi(alert))));
    }

    public getNewsletters(): Observable<NewsLetters> {
        return this.apiClient.get('/sig/newsletter/get-newsletters');
    }

    public getLinkBlocks(): Observable<LinkBlocks> {
        return this.apiClient.get('/sig/link-blocks');
    }

    public getJobs(): Observable<Jobs> {
        return this.apiClient.get('/sig/work-at/get-jobs');
    }

    public getJob(jobId: number): Observable<Job> {
        return this.apiClient.get(`/sig/work-at/get-job/${jobId}`);
    }

    public switchToOriginalUser(): Observable<any> {
        return this.apiClient.get('/core/util/switch_user?_switch_user=_exit', {dispatchError: false});
    }

    public getBackendBaseUrl(): Observable<string> {
        return this.apiClient.get('/sig/util/backend_base_url');
    }

    public getAppSearchQuerySuggestions(term: string): Observable<any> {
        return this.apiClient.get(`/sig/app-search/query-suggestions?term=${term}`);
    }

    public getAppSearch(params: HttpParams): Observable<AppSearch> {
        return this.apiClient.get(`/sig/app-search/search`, {params})
                .pipe(map((result: any) => {
                    if (typeof result === 'object' && result[`guid` as keyof typeof result]) {
                        const article = result as Article

                        this.router.navigate(
                                [
                                    '/catalogue/article',
                                    article.guid,
                                    slugify(article.name)
                                ]
                        ).then()

                        return {} as AppSearch;
                    } else {
                        return this.appSearchAdapter.fromApi(result)
                    }
                }))
    }

    public logClickThrough(params: HttpParams): Observable<any> {
        return this.apiClient.get(`/sig/app-search/log-click-through`, {params});
    }
}


import { Injectable } from "@angular/core";
import {FormFieldOption, FormFields} from '../models/form-fields.model';
import {NgForm} from '@angular/forms';

@Injectable()
export class FormFieldService {

    /**
     * Get form fields grouped
     */
    public getFormFieldsGrouped = (formFields: FormFields[], model: any) => {
        return formFields.reduce(
            (formFieldsGrouped: {}, formField: FormFields) => {
              if (formField.f) {
                  if (formField.t === 'se' && model[formField.f] === undefined) {
                      model[formField.f] = null;
                  }

                  if (formField.t === 'cl') {
                      if (model[formField.f] === undefined) {
                          model[formField.f] = [];
                      }
                      model[formField.f].forEach(value => {
                          formField.opt.filter(option => {
                              return option.id === value;
                          }).map(option => option.checked = true);
                      })
                  }

                formFieldsGrouped[formField.f] = formField;
              }

              // If we have children, let's add their values too
              if (!formFieldsGrouped && formField.c) {
                formFieldsGrouped = this.getFormFieldsGrouped(formField.c, model);
              }

              // Return the grouped form fields
              return formFieldsGrouped;
            },
            {}
        );
    };

    /**
     * Update checkbox list options in record model
     */
    public updateCheckboxListOptions(field: FormFields, model: any) {
        // First remove all options of current field from values
        // This makes it possible to use the same model in multiple separate checkbox lists on one form
        for (let i in field.opt) {
            const option = field.opt[i];
            if (model[field.f] === undefined) {
                model[field.f] = [];
            }
            const index = model[field.f].indexOf(option.id, 0);
            if (index > -1) {
                model[field.f].splice(index, 1);
            }
        }

        // Then add values of checked values of current field
        const values = field.opt.filter((option: FormFieldOption) => {
            return option.checked;
        }).map(option => {
            return option.id;
        });
        model[field.f] = model[field.f].concat(values);
    }

    /**
     * Mark form fields touched
     */
    public markFormFieldsTouched(form: NgForm) {
        Object.values(form.form.controls).forEach(control => {
            control.markAsTouched();
        });
    }
}

import {Injectable} from '@angular/core';

@Injectable()
export class UtilService {

  /**
   * Check if valid url
   */
  public isValidUrl(url: string): boolean {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  }
}
